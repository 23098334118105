import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    GetNotificationGQL,
    GetNotificationQuery, Notification,
    ReadNotificationGQL,
    UserNotificationDto
} from "../../../generated/graphql";
import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
    notificationsQuery: QueryRef<GetNotificationQuery>;
    notifications: Observable<UserNotificationDto>;


    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public getNotifications: GetNotificationGQL,
                public readNotification: ReadNotificationGQL
    ) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.notificationsQuery = this.getNotifications.watch({}, {});
        this.notificationsQuery.startPolling(5000);
        this.notifications = this.notificationsQuery.valueChanges
            .pipe(
                map(result => result.data.notificationsByUser as UserNotificationDto)
            );
    }


    actionNotification(model: Notification) {
        if (model.isRead) {
            this.actionRead(model.relationId);
        } else {
            this.readNotification.mutate({
                id: model.id
            }).subscribe((result) => {
                this.actionRead(model.relationId);
            });
        }
    }

    actionRead(relationId: string) {
        const user = this.userService.getUserLocal();

        if (user.profile === 'admin') {
            this.router.navigate([`certificate/${relationId}`]);
        } else {
            this.router.navigate([`home/${relationId}`]);
        }
    }
}
