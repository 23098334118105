<div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-flex align-items-center justify-content-center">
                            <div class="text-center">
                                <img src="assets/images/logo-dark.png" alt="EuroFarma" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-2">Nova Senha</h1>
                                    <p class="mb-4">Basta digitar sua nova senha abaixo e prosseguir com a navegação!</p>
                                </div>
                                <form class="user row">
                                    <div class="col-12">
                                        <div class="input-material">
                                            <input id="field2" name="field2" class="form-control" type="text" maxlength="250"
                                                   [(ngModel)]="model.email"
                                                   required/>
                                            <label for="field2">E-mail</label>
                                        </div>
                                    </div>
<!--                                    <div class="col-12">-->
<!--                                        <div class="input-material">-->
<!--                                            <input id="field" name="code" class="form-control" type="text" maxlength="250"-->
<!--                                                   [(ngModel)]="model.code"-->
<!--                                                   required/>-->
<!--                                            <label for="field">Código validação</label>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                    <div class="col-12 col-lg-6">
                                        <div class="input-material">
                                            <input id="password" name="password" class="form-control" type="password" maxlength="250"
                                                   [(ngModel)]="model.newPassword"
                                                   required/>
                                            <!--<a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('confirmPassword')"
                                               style="position: absolute;top: 8px; right: 6px;">
                                                <svg-icon [src]="!handlerViewPasswordIcon('confirmPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                            </a>-->
                                            <label for="password">Nova Senha</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="input-material">
                                            <input id="confirmPassword" name="confirmPassword" class="form-control" type="password" maxlength="250"
                                                   [(ngModel)]="modelConfirmPassword"
                                                   required/>
                                            <!--<a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('confirmPassword')"
                                               style="position: absolute;top: 8px; right: 6px;">
                                                <svg-icon [src]="!handlerViewPasswordIcon('confirmPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                            </a>-->
                                            <label for="confirmPassword">Confirmar Nova Senha</label>
                                        </div>
                                    </div>
                                    <hr>
                                    <button type="submit" class="btn btn-primary btn-user btn-block text-white"
                                        (click)="actionResetPassword()">
                                        Redefinir Senha
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

