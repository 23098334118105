import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {AccessControlCheckListComponent} from './views/access-control/check-list/access-control-check-list.component';
import {CertificateComponent} from './views/certificate/certificate.component';
import {CertificateDetailComponent} from './views/certificate/certificate-detail/certificate-detail.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {AdminComponent} from './views/admin/admin.component';
import {UserResolver} from './resolver/user.resolve';
import {UserPermissionResolver} from './resolver/user-permission.resolve';

const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'login/:id', component: LoginComponent
    },
    {
        path: 'recovery/:code', component: RecoveryComponent
    },
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: 'home', component: HomeComponent},
            {path: 'home/:id', component: HomeComponent},
            {path: 'dashboard', component: DashboardComponent},
            {
                path: 'certificate', component: CertificateComponent, resolve: {
                    user: UserPermissionResolver
                }
            },
            {
                path: 'certificate/:id', component: CertificateDetailComponent, resolve: {
                    user: UserPermissionResolver
                }
            },
            {path: 'user', component: UserComponent},
            {path: 'admin', component: AdminComponent},
            {
                path: 'access-control', component: AccessControlComponent, children: [
                    {path: '', component: AccessControlHomeComponent},
                    {path: 'check-list', component: AccessControlCheckListComponent},
                ]
            },
            {path: '', component: HomeComponent},
        ],
        resolve: {
            user: UserResolver
        }
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
