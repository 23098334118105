<svg-icon class="bell-icon" src="assets/svg/bell.svg"></svg-icon>
<a href="javascript:void(0)" *ngIf="notifications | async">
    <span class="notification_number">{{(notifications | async)?.unread}}</span>
</a>
<div class="header__expanded">
    <ul *ngIf="(notifications | async) != null">
        <li *ngIf="(notifications | async)?.notifications == null || (notifications | async)?.notifications?.length == 0">
            <p class="pt-3 pb-3">{{'notification'|translate}}</p></li>
        <li *ngFor="let n of (notifications | async)?.notifications" (click)="actionNotification(n)">
            <p><strong>{{n.title}}</strong></p>
            <p>{{n.message}}</p>
        </li>
    </ul>
</div>

