import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {Cid, Scalars, SearchCidGQL, SearchCidQuery} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";


@Component({
    selector: 'cid-autocomplete',
    templateUrl: './cid-autocomplete.component.html',
    styleUrls: ['./cid-autocomplete.scss']
})
export class CidAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('auto') auto;

    public _selected: AutocompleteItem;
    @Input()
    set selected(item: AutocompleteItem) {
        // console.log(item);
        this._selected = item;
        if (item == null && this.auto != undefined) {
            this.auto.clear();
            this.auto.close();
        }
    }

    @Output() onSelected = new EventEmitter<AutocompleteItem>();

    searchCidQuery: QueryRef<SearchCidQuery>;

    keyword = 'name';
    list: AutocompleteItem [] = [];

    constructor(public searchCidGQL: SearchCidGQL) {
    }

    ngOnInit(): void {
        this.searchCidQuery = this.searchCidGQL.watch({
            keyword: ''
        });

        this.searchCidQuery.valueChanges.subscribe(({data}) => {
            this.list = data.cid.map(e => new AutocompleteItem(e.cid, e.cid));
        });
    }


    ngAfterViewInit(): void {
        // console.log('ngAfterViewInit');
    }

    ngOnDestroy(): void {
        // console.log('ngOnDestroy');
    }

    selectEvent(item) {
        // console.log(item);
        this.onSelected.emit(item);
    }

    onChangeSearch(val: string) {
        this.searchCidQuery.refetch({
            keyword: val
        });
    }

    onFocused(e) {
        // do something when input is focused
    }

    onCleared() {
        this._selected = null;
    }
}


export class AutocompleteItem {
    text: string;
    value: any;

    constructor(text: string, value: any) {
        this.text = text;
        this.value = value;
    }
}
