import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-access-control-home',
    templateUrl: './access-control-home.component.html',
    styleUrls: ['./access-control-home.component.scss']
})
export class AccessControlHomeComponent extends BaseComponent implements OnInit {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.setDatePicker('picker-period', (start, end, label) => {
            /*this.searchIdeaInput.start = start.utcOffset(0, true).format();
            this.searchIdeaInput.end = end.utcOffset(0, true).format();*/
        });
    }

    UpdateUser(list: number) {

    }

    actionDelete(id: any) {

    }
}
