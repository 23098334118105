import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../generated/graphql';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    private tokenDataSource = new BehaviorSubject<string>(null);
    token = this.tokenDataSource.asObservable();
    public culture: string;

    private userPreDataSource = new BehaviorSubject<User>(new User());
    userPre = this.userPreDataSource.asObservable();

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    private notificationDataSource = new BehaviorSubject<Notification[]>([]);
    notification = this.notificationDataSource.asObservable();


    constructor(http: HttpClient) {
        super(http);
    }

    updateToken(token: string) {
        localStorage.setItem('token', token);
    }

    getExcelUsers(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelUsersMedicalCertificate(model) {
        const url = `${this.BASE_URL}/users/search-user/medical-certificate/excel`;
        return this.http.post(url, model);
    }

    getStatusAuthenticated() {
        const t = localStorage.getItem('token') as string;
        if (t != null && typeof t !== 'undefined') {
            if (this.getToken() == null || typeof this.getToken() !== 'undefined') {
                this.tokenDataSource.next(t);
            }
            return true;
        } else {
            return false;
        }
    }

    create(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user`;
        return this.http.put(url, model);
    }

    updateUser(user: User, tokenChanged: boolean = false, t: string = null) {
        if (tokenChanged) {
            this.updateToken(t);
            this.tokenDataSource.next(t);
        }
        this.userDataSource.next(user);
    }

    getUserLocal(): User {
        return this.userDataSource.value;
    }

    updateUserPre(user: User) {
        this.userPreDataSource.next(user);
    }

    getToken(): string {
        return this.tokenDataSource.value;
    }

    upload(file, id, path) {
        const url = `${this.BASE_URL}/upload/${path}/${id}`;
        return new Observable((observer) => {
            //    observer.next();
            //  observer.complete();
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

}
