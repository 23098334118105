<div class="login">
    <div class="header">
        <svg-icon src="assets/svg/title.svg"></svg-icon>
        <div class="desc">
            <p>{{'atestadomedico'|translate}}</p>
        </div>
    </div>
    <div class="content">
        <div class="form">
            <b>LOGIN</b>
            <div class="input-material w-80">
                <input id="fieldRE" class="form-control" type="text" required [(ngModel)]="model.cpf"
                       [mask]="'000.000.000-00'"
                       [dropSpecialCharacters]="false"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModelUser($event, 'cpf')" />
                <label for="fieldRE">
                    <span>CPF</span>
                </label>
            </div>
            <div class="input-material w-80">
                <input id="senha" class="form-control" type="password" required [(ngModel)]="model.password"
                (keyup.enter)="actionLogin()"/>
                <label for="senha">
                    <span>Senha</span>
                </label>
            </div>
            <div class="row justify-content-end align-items-end w-80 mt-3 mb-3">
                <a href="javascript:void('');" (click)="onShowForgot()">Esqueci minha Senha</a>
            </div>
            <button class="btn btn-warning  w-80 mt-3" tabindex="0" (click)="actionLogin()" >
                <span class="pl-2">Entrar</span>
            </button>
            <button (click)="firstAccessClick()" class="btn btn-outline-primary  w-80 mt-4" tabindex="0" >
                <span class="pl-2">Primeiro Acesso</span>
            </button>
            <div class="d-flex align-items-baseline justify-content-center w-80">
                <div class="divisor"></div>
                <p class="mt-4 mx-3">
                    ou
                </p>
                <div class="divisor"></div>
            </div>
            <a class="btn btn-primary btn-lg text-white w-80" [routerLink]="'/authenticate'">
               <b class="text-white">Login Eurofarma</b>
            </a>
        </div>
    </div>
</div>

<!--modal-->
<div class="modal-custom modal fade" id="modalLogin" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        Primeiro acesso
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalLogin')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <div class="col-12 d-flex justify-content-center">

                            <img class="mb-3" id="svg_login_profile"  src="assets/images/photologin.png" (click)="showModal('modalCropLogin')"/>
                            <img class="img_login" *ngIf="croppedImage && !!isNullOrUndefined" src="{{croppedImage}}" alt="">
                        </div>
                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="reUser" class="form-control" type="text" [(ngModel)]="modelUser.re"
                                       (blur)="actionVerifyUserFirstAccess($event)"
                                       required   maxlength="15"/>
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!--CPF-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material" >
                                <input id="userCPF" class="form-control" type="text" required maxlength="150"
                                       [mask]="'000.000.000-00'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                       (blur)="onClearModelUser($event, 'cpf')"
                                       (ngModelChange)="actionVerifyUserFirstAccess($event)"
                                       [(ngModel)]="modelUser.cpf"
                                       />
                                <label for="userCPF">CPF</label>
                            </div>
                        </div>

                        <ng-container>
                            <!-- PROFILE DROPDOWN -->
                            <!--NAME-->
                            <div class="col-12 col-sm-12">
                                <div class="input-material">
                                    <input id="nameUser" class="form-control" type="text" [(ngModel)]="modelUser.name"
                                           [disabled]="flagFirstAccessUser"
                                       required maxlength="150"/>
                                    <label for="nameUser">Nome Completo</label>
                                </div>
                            </div>
                            <!-- UNITY DROPDOWN -->
                            <div class="col-12 col-sm-6">
                                <div class="input-material flex-grow-1">
                                    <select id="countryUser" class="form-control" name="type" required
                                            [disabled]="lockUnity"
                                            [(ngModel)]="modelUserPreCountry" (ngModelChange)="getUnitiesByCountry($event)">
                                        <option selected
                                                [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                        <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name}}</option>
                                    </select>
                                    <label [ngClass]="{'disabled' : lockUnity}" for="countryUser" class="mb-1 mr-3">País</label>
                                </div>
                            </div>
                            <!-- COUNTRY DROPDOWN -->
                            <div class="col-12 col-sm-6">
                                <div class="input-material flex-grow-1" >
                                    <select id="unityUser" class="form-control" name="type" required [disabled]="lockUnity"
                                            [(ngModel)]="modelUserPreUnity">
                                        <option selected
                                                [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                        <option *ngFor="let u of unities" [ngValue]="u.id">{{u.name}}</option>
                                    </select>
                                    <label [ngClass]="{'disabled' : this.lockUnity}" for="unityUser" class="mb-0 mr-3" >Unidade</label>
                                </div>
                            </div>
                            <!-- PHONE -->
                            <div class="col-12 col-sm-6">
                                <div class="input-material">
                                    <input id="userPhone" class="form-control" type="text"
                                           [disabled]="flagFirstAccessUser" [(ngModel)]="modelUser.phone"
                                           [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                           [dropSpecialCharacters]="false"
                                           [clearIfNotMatch]="true"
                                           (blur)="onClearModelUser($event, 'phone')"
                                           required  maxlength="150"/>
                                    <label for="userPhone">Telefone de Recado</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="input-material">
                                    <input id="userPhoneA" class="form-control" type="text"
                                           [disabled]="flagFirstAccessUser" [(ngModel)]="modelUser.cellphone"
                                           [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                           [dropSpecialCharacters]="false"
                                           [clearIfNotMatch]="true"
                                           (blur)="onClearModelUser($event, 'cellphone')"
                                           required  maxlength="150"/>
                                    <label for="userPhoneA">Celular Pessoal</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="input-material">
                                    <input id="userEmail" class="form-control" type="text"
                                           [disabled]="flagFirstAccessUser" [(ngModel)]="modelUser.email"
                                           required  maxlength="150"/>
                                    <label for="userEmail">E-mail pessoal</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="input-material disabled">
                                    <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelUser.officeEmail"
                                           [readOnly]="modelUserPre.isOffice"
                                           [disabled]="modelUserPre.isOffice || flagFirstAccessUser"
                                           required  maxlength="250"/>
                                    <label for="userEmailConfirm" class="disabled">E-mail EuroFarma</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6" *ngIf="isNullOrUndefined(modelUserPre.isOffice)">
                                <div class="input-material">
                                    <input id="userPass" class="form-control" type="password"
                                           [disabled]="flagFirstAccessUser" [(ngModel)]="modelUser.password"
                                           required  maxlength="150"/>
                                    <label for="userPass">Senha</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6" *ngIf="isNullOrUndefined(modelUserPre.isOffice)">
                                <div class="input-material">
                                    <input id="userPassConfirm" class="form-control" type="password"
                                           [disabled]="flagFirstAccessUser" [(ngModel)]="modelConfirmPassword"
                                           required maxlength="150"/>
                                    <label for="userPassConfirm">Confirmar Senha</label>
                                </div>
                            </div>

                            <div class="col-12 my-3">
                                <button class="btn btn-primary btn-lg text-white w-100" (click)="actionValidateUser()"><p class="text-white m-0">Confirmar</p></button>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalLogin2" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        Primeiro acesso
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalLogin2')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mt-5 mb-2">
                        <div class="col-12 row d-flex justify-content-center mb-3">
                            <div class="d-flex justify-content-center col-md-6 col-12">
                                <ng-lottie class="mb-3"
                                           width="80px" [options]="accessAnimation"></ng-lottie>
                            </div>
                          <div class="col-md-6 col-12">
                              <h3 class="text-primary">
                                  <b>Enviamos  para seu  celular um código de validação.</b>
                              </h3>
                          </div>
                        </div>
                        <!--NAME-->
                        <div class="d-flex justify-content-around p-5">
                            <code-input [isCodeHidden]="false"
                                        [codeLength]="6"
                                        [inputType]="'text'"
                                        [isCharsCode]="true"
                                        [inputType]="'text'"
                                        [autocapitalize]="'characters'"
                                        (codeCompleted)="onCodeCompleted($event)">
                            </code-input>

                        </div>
                        <div class="row justify-content-center w-100">
                            <a href="javascript:void('');" (click)="actionReSendCode()">Reenviar Código</a>
                        </div>
                        <!--RE-->
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3" (click)="actionSendCode()"
                            ><p class="text-white m-0">Concluir</p></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalForgot" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>Esqueci minha senha</b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalForgot')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mt-5 mb-2">
                        <div class="col-12 row d-flex justify-content-center mb-3">
                            <div class="d-flex justify-content-center col-md-6 col-12">
                                <img  src="assets/images/photo.png" class="mb-3"  >
                            </div>
                            <div class="col-md-6 col-12">
                                <h3 class="text-primary">
                                    <b>Recupere sua senha informando o CPF cadastrado.</b>
                                </h3>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material input_small">
                                <input id="forgotPass" class="form-control" type="text" [(ngModel)]="modelForgotCPF"
                                       [mask]="'000.000.000-00'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                       required maxlength="250"/>
                                <label for="forgotPass">CPF</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100 mt-3" (click)="actionForgotPassword()"
                            ><p class="text-white m-0">Enviar</p></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="4/4"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
