<div class="page-header full">
    <div class="container no-wrap justify-content-between pt-3 pb-4 pt-lg-4 pl-0">
        <h2 class="page-header__title large">
            <strong>B.I.O | </strong> Dashboard
        </h2>
    </div>
</div>

<div class="dashboard">
    <div class="container">
        <section class="dashboard_top row">
            <div class="col-sm-6 col-lg-3">
                <div class="input-material select mb-lg-0">
                    <select class="form-control" required>
                        <option disabled value hidden selected></option>
                        <option>1</option>
                        <option>2</option>
                    </select>
                    <label>Todas as Unidades</label>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="input-material mb-0">
                    <input class="form-control" type="text" required />
                    <label>
                        <span>Data da Ocorrência</span>
                        <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                    </label>
                </div>
            </div>
        </section>

        <section class="row">
            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">Impacto nas <strong>Atividades</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <img src="assets/images/chart-1.png" class="d-block mx-auto" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">Perdas em <strong>Função de Ocorrências</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <img src="assets/images/chart-2.png" class="d-block mx-auto" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">Perdas <strong>Evitadas | Recuperadas</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <img src="assets/images/chart-3.png" class="d-block mx-auto" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 col-lg-6">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">Tipo de <strong>Ocorrêcias</strong></p>
                            <hr>
                        </div>
                        <div class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <img src="assets/images/chart-4.png" class="d-block mx-auto" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </div>
</div>




