import { Component, OnInit } from '@angular/core';
import * as svg4everybody from 'svg4everybody/dist/svg4everybody';
import {BaseComponent} from './base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    svg4everybody();
    super.switchLanguage('pt');
  }
}
