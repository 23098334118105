import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// NOVAS VIEWS
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {SettingsComponent} from './views/settings/settings.component';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AlarmComponent} from './views/alarm/alarm.component';
import {AlarmHomeComponent} from './views/alarm/home/alarm-home.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {SettingsAlarmComponent} from './views/settings/alarm/settings-alarm.component';
import {CertificateComponent} from './views/certificate/certificate.component';
import {CertificateDetailComponent} from './views/certificate/certificate-detail/certificate-detail.component';
import {AccessControlCheckListComponent} from './views/access-control/check-list/access-control-check-list.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {NgxMaskModule} from "ngx-mask";
import {RecoveryComponent} from "./views/recovery/recovery.component";
import {AutosizeModule} from "ngx-autosize";
import { AdminComponent } from './views/admin/admin.component';
import {CodeInputModule} from "angular-code-input";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {CidAutocompleteComponent} from "./views/components/cid-autocomplete/cid-autocomplete.component";

registerLocaleData(localePt, 'pt-BR');

export function playerFactory() {
    return player;
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        HomeComponent,
        AutocompleteComponent,
        NotificationComponent,
        UserComponent,
        AuthenticateComponent,
        LoginComponent,
        DashboardComponent,
        SettingsComponent,
        AccessControlComponent,
        AlarmComponent,
        AlarmHomeComponent,
        AccessControlHomeComponent,
        SettingsAlarmComponent,
        AccessControlCheckListComponent,
        CertificateComponent,
        CropImageComponent,
        CertificateComponent,
        CertificateDetailComponent,
        RecoveryComponent,
        AdminComponent,
        CidAutocompleteComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        CodeInputModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxMaskModule.forRoot(),
        GraphQLModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ImageCropperModule,
        NgApexchartsModule,
        NgxPaginationModule,
        AutosizeModule,
        [LottieModule.forRoot({ player: playerFactory })],
        AutocompleteLibModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
