import {Component, OnInit} from '@angular/core';
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {BaseComponent} from "../../base/base.component";
import {CreateUserGQL, MedicalCertificate, ResetPasswordGQL, ResetPasswordInput} from "../../../generated/graphql";

@Component({
    selector: 'app-recovery',
    templateUrl: './recovery.component.html',
    styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent extends BaseComponent implements OnInit {

    model: ResetPasswordInput = new ResetPasswordInput();
    modelConfirmPassword = '';

    constructor(public router: Router,
                public activeRouter: ActivatedRoute,
                public loading: NgxSpinnerService,
                private resetPasswordGQL: ResetPasswordGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.activeRouter.params.subscribe(param => {
            console.log(param);
            if (!this.isNullOrUndefined(param)) {
                this.model.code = param.code;
            }
        });
    }

    actionResetPassword() {
        const arrValidateFields = [
            {value: this.model.email, text: 'E-mail <br />'},
            // {value: this.model.code, text: 'Código validação <br />'},
            {value: this.model.newPassword, text: 'Nova senha <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningFillCorrect')} <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isEMailValid(this.model.email)) {
            return;
        }

        if (this.model.newPassword !== this.modelConfirmPassword) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                this.translate.instant('baseComponent.warningPasswordFail'), 'warning');
            return;
        }

        this.resetPasswordGQL.mutate({input: this.model}).subscribe((result) => {
            // console.log('sucesso')
            // console.log(result);
            this.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningPasswordSuccess'), 'success');
            this.router.navigate(['/login']);
        });
    }

}
