import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getExcelCertificate(model) {
        const url = `${this.BASE_URL}/medical-certificates/search-certificates/excel`;
        return this.http.post(url, model);
    }

    getExcelMedicalCertificate(model) {
        const url = `${this.BASE_URL}/medical-certificates/medical-certificate/excel`;
        return this.http.post(url, model);
    }

    upload(file, urlParameter) {
        const url = `${this.BASE_URL}${urlParameter}`;
        return new Observable((observer) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }

    medicalCertificateFile(model) {
        const url = `${this.BASE_URL}/medical-certificates/create-certificate-job/files`;
        return this.http.post(url, model);
    }

    medicalCertificateCheck(id) {
        const url = `${this.BASE_URL}/medical-certificates/check-certificate-job/${id}/files`;
        return this.http.get(url);
    }

}
