import {Component, OnInit} from '@angular/core';
import {UserService} from '../../service/user.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private service: UserService) {
    }

    ngOnInit(): void {
        this.service.token = null;
        localStorage.clear();
    }

}
