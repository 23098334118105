import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';
import {of, Observable} from 'rxjs';
import {HttpErrorResponse} from "@angular/common/http";

declare var $: any;

export class BaseComponent {
    enumProfile = ['user', 'admin'];
    enumStatus = ['received', 'review', 'reproved', 'approved'];
    enumType = ['certificate', 'consult', 'escort', 'maternity', 'donation'];
    enumTypeConsult = ['exam', 'scheduledMedicalAppointment', 'physiotherapyPsychology', 'dentist'];
    enumTypeAcompanhante = ['hospitalization', 'consult', 'complexExam'];
    enumPerson = ['son>16', 'son>18', 'spouse', 'son', 'exceptional'];
    enumPerson1 = ['spouse', 'son>18'];
    enumPerson2 = ['son>16', 'exceptional'];
    enumPerson3 = ['son', 'spouse'];
    dateNow = moment().utcOffset(0, true).format();
    enumUF = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    getBaseURL() {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning', callback: any = null) {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then( (result) => {
            if (callback != null) {
                callback();
            }
        });
    }

    confirmMessage(t: string, m: string, callback: any = null, callbackRefuse: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            } else {
                if (callbackRefuse != null) {
                    callbackRefuse();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        console.log('error');
        console.log(error);

        if (typeof(error) === 'string') {
            error = JSON.parse(error);
        }

        if (error.statusCode === 401 || error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        const m = this.isNullOrUndefined(error.message) ? error.error.message : error.message;

        if (error.statusCode === 400 || error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), m, 'warning');
            return;
        }

        if (error.statusCode === 500 || error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value, verifyLength: boolean = true) {
        return typeof value === 'undefined' || value == null || verifyLength && value.length === 0;
    }


    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    isCPFValid(strCPF) {
        let Soma;
        let Resto;
        Soma = 0;
        const strErrorTitle = 'Atenção';
        const strErrorDescription = 'Informe um CPF válido para prosseguir.';
        strCPF = strCPF.replace(/\D+/g, '');
        if (strCPF === '00000000000' || strCPF === '12112112155') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        for (let i = 1; i <= 9; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        return true;
    }

    isCNPJValid(cnpj) {
        let i;
        const strErrorTitle = 'Atenção';
        const strErrorDescription = 'Informe um CNPJ válido para prosseguir.';
        cnpj = cnpj.replace(/[^\d]+/g, '');

        /*if (cnpj === '') {
          this.showMessage(strErrorTitle, strErrorDescription, 'warning');
          return false;
        }*/

        if (cnpj.length !== 14) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Valida DVs
        const tamanho = cnpj.length - 2;
        const numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        const resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== Number(digitos.charAt(0))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        return true;
    }

    getMonthNames() {
        let arrMonth = [];
        switch (this.translate.currentLang) {
            case 'en':
                arrMonth = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];
                break;
            case 'es':
                arrMonth = [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Deciembre'
                ];
                break;
            default:
                arrMonth = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ];
                break;
        }
        return arrMonth;
    }

    getDaysOfDayWeek() {
        let arr = [];
        switch (this.translate.currentLang) {
            case 'en':
                arr = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat'
                ];
                break;
            case 'es':
                arr = [
                    'Dom',
                    'lun',
                    'mar',
                    'mie',
                    'jue',
                    'vie',
                    'Sab'
                ];
                break;
            default:
                arr = [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ];
                break;
        }
        return arr;
    }

    setDatePicker(id: string, callback, date = 30): void {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    setDate(id: string, callback, startDate?, timepicker?, maxDate?, format = 'DD/MM/YYYY') {
        $('.' + id).daterangepicker({
            timePicker: timepicker ? true : false,
            timePicker24Hour: timepicker ? true : false,
            startDate: startDate ? moment(startDate).utc() : moment(),
            singleDatePicker: true,
            maxDate: maxDate,
            cancelButtonClasses: 'btn-danger',
            locale: {
                customRangeLabel: 'Personalizar',
                format: format,
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    handlerBoxSize(): void {
        const el = $('.js-set-border');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            const size = $(x).parent()[0].getBoundingClientRect().height;
            $(x)
                .css({
                    height: size
                })
                .find('span').css({
                width: size
            });
        });
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    onLogout() {
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningExit'), () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getUrlCertificateImage(url: string) {
        return url.indexOf('pdf') >= 0 ? '/assets/images/ico-pdf.png' : url;
    }

}
