import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {UserService} from "../service/user.service";

declare var $: any;

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) {

    }

    requests = 0;
    arrNoLoader = [
        '/assets',
        '/svg',
        '/user',
        '/language',
        '/store',
        '/notification',
        '/order/cart/',
        '/google-place',
        '/store-product/',
        'GetNotification'
    ];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests = this.requests + 1;
        let countLoaderBlock = 0;
        if (req.body != null && req.body.operationName != null) {
            // console.log(req.body.operationName);
            this.arrNoLoader.map((e, i) => {
                if (req.body.operationName.indexOf(e) >= 0) {
                    countLoaderBlock++;
                }
            });
        }
        this.arrNoLoader.map((e, i) => {
            if (req.url.indexOf(e) >= 0) {
                countLoaderBlock++;
            }
        });
        if (countLoaderBlock === 0) {
            this.toggleLoader(true);
        }

        const culture = localStorage.getItem('culture') as string;

        let newHeaders = req.headers;
        if (this.userService.getToken() != null && typeof this.userService.getToken() !== 'undefined') {
            newHeaders = newHeaders.append('Authorization', 'Bearer ' + this.userService.getToken());
        }
        if (culture != null) {
            newHeaders = newHeaders.append('Culture', culture);
        }

        const authReq = req.clone({headers: newHeaders});
        return next.handle(authReq).pipe(
            finalize(() => {
                this.requests = this.requests - 1;
                if (this.requests === 0) {
                    if (countLoaderBlock !== 0) {
                        this.toggleLoader(false);
                        // this.loading.hide();
                    }
                }
            })
        );
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

}
