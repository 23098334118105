import {AfterViewInit, Component, OnInit, OnDestroy, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    ApproveMedicalCertificateGQL,
    Country,
    CreateMedicalCertificateCommentGQL,
    CreateMedicalCertificateCommentInput,
    CreateMedicalCertificateGQL,
    CreateMedicalCertificateInput,
    GetCountriesGQL,
    GetUnitiesByCountryGQL,
    MedicalCertificate,
    MedicalCertificateComment, MedicalCertificatePaginator,
    SearchMedicalCertificateGQL,
    SearchMedicalCertificateInput,
    SearchMedicalCertificateQuery,
    SearchMedicalCertificateQueryVariables,
    Unity,
    UpdateMedicalCertificateGQL, UpdateMedicalCertificateInput,
    UpdateUserGQL,
    UpdateUserInput,
    UpdateUserUnityInput,
    User,
    AcceptTermsGQL, RemoveMedicalCertificateImageGQL
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {onError} from '@apollo/client/link/error';
import {AnimationOptions} from 'ngx-lottie';
import {AutocompleteItem} from '../components/cid-autocomplete/cid-autocomplete.component';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterContentInit, OnDestroy {

    modelUser: User = new User();
    unityId: string;
    btnNew = false;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    croppedAttachment: any = [];
    file: File = null;
    fileList: File[] = [];
    searchCertificateQuery: QueryRef<SearchMedicalCertificateQuery, SearchMedicalCertificateQueryVariables>;
    certPage: MedicalCertificatePaginator = new MedicalCertificatePaginator();
    certificate: MedicalCertificate = new MedicalCertificate();
    searchInput: SearchMedicalCertificateInput = new SearchMedicalCertificateInput();

    createInput: CreateMedicalCertificateInput = new CreateMedicalCertificateInput();
    countries: Country[] = [];
    countryFilter: Country = new Country();
    unities: Unity[] = [];
    usersAnimation: AnimationOptions = {
        path: 'assets/lottie/users.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };
    attentionAnimation: AnimationOptions = {
        path: 'assets/lottie/attention.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };
    message: AnimationOptions = {
        path: 'assets/lottie/message.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };
    userId: string;
    createCommentInput: CreateMedicalCertificateCommentInput = new CreateMedicalCertificateCommentInput();

    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    reviewReadOnly = true;

    cidSelected: AutocompleteItem;

    days = [];
    loadingMenssage = '';

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public searchMedicalCertificateGQL: SearchMedicalCertificateGQL,
                public createMedicalCertificateGQL: CreateMedicalCertificateGQL,
                public updateMedicalCertificateGQL: UpdateMedicalCertificateGQL,
                public approveMedicalCertificateGQL: ApproveMedicalCertificateGQL,
                public removeMedicalCertificateImageGQL: RemoveMedicalCertificateImageGQL,
                public updateUserGQL: UpdateUserGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public createMedicalCertificateCommentGQL: CreateMedicalCertificateCommentGQL,
                public acceptTermsGQL: AcceptTermsGQL,
                public route2: ActivatedRoute,
                public translate: TranslateService) {
        super(router, loading, translate);

    }

    ngOnInit() {
        $(() => {
            $('#sidebar').addClass('is-hide');
        });
        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchInput.start = start.utcOffset(0, true).format();
            this.searchInput.end = end.utcOffset(0, true).format();
        });

        this.days = [];
        for (let i = 1; i <= 15; i++) {
            if (i == 1) {
                this.days.push({value: i.toString(), text: i + ' dia'});
            } else {
                this.days.push({value: i.toString(), text: i + ' dias'});
            }
        }
        this.days.push({value: '+15', text: 'Mais de 15 dias'});
    }

    actionList() {
        this.searchCertificateQuery = this.searchMedicalCertificateGQL.watch({input: this.searchInput});
        this.searchCertificateQuery.valueChanges.subscribe(({data}) => {
            this.certPage = data.searchMedicalCertificate as MedicalCertificatePaginator;

            this.route2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url)) {
                    this.resumeCertificate(this.certPage.results.find(x => x.id === url.id));
                }
            });
        }, error => super.onError(error));
    }

    onSearch() {
        this.loading.show();
        this.searchCertificateQuery.refetch({input: this.searchInput}).finally(() => {
            this.loading.hide();
        }).catch(error => super.onError(error));
    }

    changeUnties(id: string) {
        this.unityId = null;
        this.getUnitiesByCountryGQL.watch({input: id}).valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Unity[];
        });
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.userId = data.id;
                    this.modelUser = Object.assign({}, data) as User;
                    this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                }
            }
        });
        this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.countries = data.getCountries as Country[];
        });
        this.actionList();
    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
        $('#modalUser, #modalRevision, #modalAtestado, #modalDeclaracao, #modalAcompanhante, #modalMaternidade, #modalDoacao, #modalConfirm').remove();
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    setCroppedImageAttachment(o: any) {
        this.croppedAttachment.push(o.thumb);
        this.handleUpload(o.file, true);
    }

    handleUpload(event, list = false) {
        let files = event;
        if (this.isNullOrUndefined(files.length)) {
            files = [];
            files.push(event);
        }
        if (files.length > 0) {
            if (files[0].size > 20000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningUpload'), 'info');
                this.croppedAttachment = [];
                return;
            }
            if (files[0].type.toLowerCase().indexOf('pdf') === -1
                && files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('baseComponent.warningUploadExtensions'), this.translate.instant('baseComponent.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(files[0]);
        if (list) {
            this.fileList.push(files[0]);
        } else {
            this.file = files[0];
        }
    }

    actionCreate(m: string) {
        this.createInput = new CreateMedicalCertificateInput();
        this.createInput.cid = '';
        this.cidSelected = null;
        this.fileList = [];
        this.croppedAttachment = [];

        this.setDate('emissionDate', (start, end) => {
            this.createInput.emissionDate = end.utcOffset(0, true).format();
        }, null, null, new Date());
        this.setDate('appointmentDate', (start, end) => {
            this.createInput.appointmentDate = end.utcOffset(0, true).format();
        });
        this.setDate('appointmentStart', (start, end) => {
            this.createInput.appointmentStart = end.utcOffset(0, true).format();
        }, null, true, null, 'DD/MM/YYYY HH:mm');
        this.setDate('appointmentEnd', (start, end) => {
            this.createInput.appointmentEnd = end.utcOffset(0, true).format();
        }, null, true, null, 'DD/MM/YYYY HH:mm');
        this.setDate('timeOffStartDate', (start, end) => {
            this.createInput.timeOffStartDate = end.utcOffset(0, true).format();
        });

        switch (m) {
            case 'modalAtestado':
                this.createInput.type = 'certificate';
                this.createInput.emissionDate = this.dateNow;
                this.createInput.appointmentDate = this.dateNow;
                break;
            case 'modalDeclaracao':
                this.createInput.type = 'consult';
                this.createInput.appointmentEnd = this.dateNow;
                this.createInput.appointmentStart = this.dateNow;
                this.createInput.appointmentDate = this.dateNow;

                break;
            case 'modalAcompanhante':
                this.createInput.type = 'escort';
                this.createInput.emissionDate = this.dateNow;
                this.createInput.appointmentEnd = this.dateNow;
                this.createInput.appointmentStart = this.dateNow;
                break;
            case 'modalMaternidade':
                this.createInput.type = 'maternity';
                this.createInput.emissionDate = this.dateNow;
                this.createInput.timeOffStartDate = this.dateNow;
                break;
            case 'modalDoacao':
                this.createInput.type = 'donation';
                this.createInput.emissionDate = this.dateNow;
                this.createInput.appointmentEnd = this.dateNow;
                this.createInput.appointmentStart = this.dateNow;
                break;
        }
        super.showModal(m);
    }

    onConfirm(m: string, edit = false) {
        let stringError;
        let arrValidateFields = [];
        switch (m) {
            case 'modalAtestado':
                arrValidateFields = [
                    {value: this.createInput.re, text: this.translate.instant('recolaborador') + '<br>'},
                    {value: this.createInput.timeOff, text: this.translate.instant('tempoatendimento') + '<br>'},
                    {value: this.createInput.crm, text: 'CRM/CRO Médico<br>'},
                    {value: this.createInput.uf, text: 'UF<br>'},
                    // {value: this.createInput.cid, text: 'CID<br>'},
                ];
                break;
            case 'modalDeclaracao':
                arrValidateFields = [
                    {value: this.createInput.re, text: this.translate.instant('recolaborador') + '<br>'},
                    {value: this.createInput.appointmentType, text: this.translate.instant('tipoatendimento') + '<br>'},
                    // {value: this.createInput.crm, text: 'CRM/CRO Médico<br>'},
                    // {value: this.createInput.uf, text: 'UF<br>'},
                    // {value: this.createInput.cid, text: 'CID<br>'},
                ];
                break;
            case 'modalAcompanhante':
                arrValidateFields = [
                    {value: this.createInput.re, text: this.translate.instant('recolaborador') + '<br>'},
                    {value: this.createInput.appointmentType, text: this.translate.instant('tipoatendimento') + '<br>'},
                    {value: this.createInput.certificateOwner, text: this.translate.instant('pessoaatestada') + '<br>'},
                ];
                break;
            case 'modalMaternidade':
                arrValidateFields = [
                    {value: this.createInput.re, text: this.translate.instant('recolaborador') + '<br>'},
                    {value: this.createInput.crm, text: 'CRM/CRO Médico<br>'},
                    {value: this.createInput.uf, text: 'UF<br>'},
                    // {value: this.createInput.cid, text: 'CID<br>'},
                ];
                break;
            case 'modalDoacao':
                arrValidateFields = [
                    {value: this.createInput.re, text: this.translate.instant('recolaborador') + '<br>'},
                ];
                break;
        }

        // if (!edit) {
        if (this.isNullOrUndefined(this.certificate.image)) {
            arrValidateFields.push({
                value: this.croppedAttachment,
                text: 'Pelo menos uma foto é obrigatória<br>'
            });
        } else {
            arrValidateFields.push({value: this.certificate.image, text: 'Pelo menos uma foto é obrigatória<br>'});
        }
        // }

        stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage((this.translate.instant('atencao')), `Preencha os campos corretamente:<br/><br/> ${stringError}`);
            return;
        }

        super.showModal('modalConfirm');
    }

    async onSave() {
        this.loading.show();
        this.loadingMenssage = this.translate.instant('createSolicitation');
        if (!this.reviewReadOnly) {
            this.onReviewUpdate();
        } else {

            let message = '';
            switch (this.createInput.type) {
                case 'certificate':
                    message = (this.translate.instant('atestadocriado'));
                    break;
                case 'consult':
                    message = (this.translate.instant('declaracaocexameriado'));
                    break;
                case 'escort':
                    message = (this.translate.instant('declaracaocriado'));
                    break;
                case 'maternity':
                    message = (this.translate.instant('licencacriado'));
                    break;
                case 'donation':
                    message = (this.translate.instant('doacaocriado'));
                    break;
                default:
                    message = (this.translate.instant('atestadocriado'));
            }

            const promiseCreate = new Promise((resolve, reject) => {
                this.createMedicalCertificateGQL.mutate({input: this.createInput}).subscribe(({data}) => {
                    super.closeModal('modalConfirm');

                    super.closeModal('modalAtestado');
                    super.closeModal('modalDeclaracao');
                    super.closeModal('modalAcompanhante');
                    super.closeModal('modalMaternidade');
                    super.closeModal('modalDoacao');

                    resolve(data.createMedicalCertificate.id);

                }, error => {
                    onError(error);
                    this.loading.hide();
                });
            });

            promiseCreate.then(async (resolver: string) => {
                this.loadingMenssage = this.translate.instant('attachingImages');
                await Promise.all(this.fileList.map((e, index) => {
                    return new Promise((res) => {
                        res(this.uploadImage(resolver, e, 'certificate-image', message, (index >= (this.fileList.length - 1))));
                    });
                }));
            });
        }
    }

    onFinish(message) {
        setTimeout(() => {
            this.createInput = new CreateMedicalCertificateInput();
            this.loading.hide();
            this.loadingMenssage = '';
            this.showMessage(this.translate.instant('baseComponent.success'), message, 'success');
            this.onSearch();
        }, 500);
    }

    onReviewUpdate() {
        this.loading.show();
        this.loadingMenssage = this.translate.instant('updateSolicitation');
        const a: UpdateMedicalCertificateInput = new UpdateMedicalCertificateInput();
        a.appointmentDate = this.createInput.appointmentDate;
        a.appointmentEnd = this.createInput.appointmentEnd;
        a.appointmentStart = this.createInput.appointmentStart;
        a.appointmentType = this.createInput.appointmentType;
        a.certificateOwner = this.createInput.certificateOwner;
        a.cid = this.createInput.cid;
        a.crm = this.createInput.crm;
        a.emissionDate = this.createInput.emissionDate;
        a.re = this.createInput.re;
        a.id = this.certificate.id;
        a.timeOff = this.createInput.timeOff;
        a.timeOffStartDate = this.createInput.timeOffStartDate;
        a.type = this.createInput.type;
        a.uf = this.createInput.uf;

        let message = '';
        switch (this.createInput.type) {
            case 'certificate':
                message = (this.translate.instant('atestadocriado'));
                break;
            case 'consult':
                message = (this.translate.instant('declaracaocexameriado'));
                break;
            case 'escort':
                message = (this.translate.instant('declaracaocriado'));
                break;
            case 'maternity':
                message = (this.translate.instant('licencacriado'));
                break;
            case 'donation':
                message = (this.translate.instant('doacaocriado'));
                break;
            default:
                message = (this.translate.instant('atestadocriado'));
        }

        const promiseUpdate = new Promise((resolve, reject) => {
            this.updateMedicalCertificateGQL.mutate({input: a}).subscribe(({data}) => {
                super.closeModal('modalConfirm');
                super.closeModal('modalRevision');

                resolve(data.updateMedicalCertificate.id);
            }, error => onError(error));
        });

        promiseUpdate.then(async (resolver: string) => {
            this.loadingMenssage = this.translate.instant('attachingImages');
            await Promise.all(this.fileList.map((e, index) => {
                return new Promise((res) => {
                    res(this.uploadImage(resolver, e, 'certificate-image', message, (index >= (this.fileList.length - 1))));
                });
            }));
        });
    }

    onUpdateUser() {

        const arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome Completo<br>'},
            {value: this.modelUser.re, text: 'RE<br>'},
            {value: this.modelUser.cpf, text: 'CPF<br>'},
            {value: this.unityId, text: 'Unidade<br>'},
            {value: this.modelUser.cellphone, text: 'Telefone de Recado<br>'},
            {value: this.modelUser.phone, text: 'Celular Pessoal<br>'},
            {value: this.modelUser.email, text: 'E-mail pessoal<br>'},
            {value: this.modelUser.officeEmail, text: 'E-mail EuroFarma<br>'},
            {value: this.modelUser.profile, text: 'Perfil<br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningFill')} ${stringError} corretamente`);
            return;
        }


        this.loading.show();
        if (!super.isNullOrUndefined(this.file)) {
            this.userService.upload(this.file, this.modelUser.id, 'user-photo').subscribe(
                data => {
                    this.saveUser();
                    this.loading.hide();
                }, error => super.onError(error));
        } else {
            this.saveUser();
        }
    }

    saveUser() {
        const unityInput: UpdateUserUnityInput = {
            id: this.unityId
        };
        const input: UpdateUserInput = {
            id: this.modelUser.id,
            name: this.modelUser.name,
            re: this.modelUser.re,
            cpf: this.modelUser.cpf,
            unity: unityInput,
            officeEmail: this.modelUser.officeEmail,
            cellphone: this.modelUser.cellphone,
            email: this.modelUser.email,
            phone: this.modelUser.phone,
            profile: this.modelUser.profile,
        };
        this.updateUserGQL.mutate({input}).subscribe(({data}) => {
            this.modelUser = data.updateUser as User;
            super.closeModal('modalUser');
            this.loading.hide();
            this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('dadosalterados')), 'success');
            this.userService.updateUser(this.modelUser, false);
        });
    }

    showModalUser() {
        this.countryFilter.id = this.modelUser.unity.country.id;
        this.changeUnties(this.modelUser.unity.country.id);
        this.unityId = this.modelUser.unity.id;
        super.showModal('modalUser');
    }

    uploadImage(id: string, file: File, path: string, message: string, finish: boolean) {
        return this.userService.upload(file, id, path).subscribe(
            data => {
                if (finish) {
                    this.onFinish(message);
                }
            }, error => super.onError(error));
    }

    removeFile(i: number) {
        if (i > -1) {
            this.croppedAttachment.splice(i, 1); // 2nd parameter means remove one item only
            this.fileList.splice(i, 1);
        }
    }

    resumeCertificate(c: MedicalCertificate) {
        if (this.modelUser.profile === 'admin') {
            this.router.navigate(['/certificate/' + c.id]);
            return;
        }
        if (this.isNullOrUndefined(c)) {
            return;
        }
        this.reviewReadOnly = c.status !== 'review';
        this.certificate = Object.assign({}, c);
        this.createInput = Object.assign({}, c);
        this.cidSelected = new AutocompleteItem(c.cid, c.cid);

        super.showModal('modalRevision');
        setTimeout(() => {
            this.setDate('modalEmissionDate', (start, end) => {
                this.createInput.emissionDate = end.utcOffset(0, true).format();
            });
            this.setDate('modalAppointmentStart', (start, end) => {
                this.createInput.appointmentStart = end.utcOffset(0, true).format();
            }, c.appointmentStart, true);
            this.setDate('modalAppointmentEnd', (start, end) => {
                this.createInput.appointmentEnd = end.utcOffset(0, true).format();
            }, c.appointmentEnd, true);
        }, 500);
    }

    actionComments(id: string) {
        if (super.isNullOrUndefined(this.createCommentInput.comment)) {
            return this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningComent'));
        }
        this.loading.show();
        this.createCommentInput.medicalCertificate = {id};
        this.createMedicalCertificateCommentGQL.mutate({input: this.createCommentInput}).subscribe(({data}) => {
            this.certificate.comment = Object.assign([], this.certificate.comment);
            this.certificate.comment.push(data.createMedicalCertificateComment as MedicalCertificateComment);
            this.createCommentInput = new CreateMedicalCertificateCommentInput();
            this.searchCertificateQuery.refetch({input: this.searchInput});
            this.loading.hide();
        }, error => super.onError(error));
    }

    onApproved(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('aprovaratestado')), () => {
            this.loading.show();
            this.approveMedicalCertificateGQL.mutate({id}).subscribe(
                (data) => {
                    this.searchCertificateQuery.refetch({input: this.searchInput});
                    super.closeModal('modalRevision');
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('atestadoaprovado')), 'success');
                }, err => super.onError(err));
        });
    }

    onSaveReview() {
        const t = {
            certificate: 'modalAtestado',
            consult: 'modalDeclaracao',
            escort: 'modalAcompanhante',
            maternity: 'modalMaternidade',
            donation: 'modalDoacao'
        };
        this.onConfirm(t[this.certificate.type], true);
    }

    pageChanged(event) {
        this.searchInput.page = event;
        this.actionList();
    }

    acceptTerms() {
        this.acceptTermsGQL.mutate().subscribe(dt => {
            this.userService.updateUser(dt.data.acceptTerms as User);
        });
    }

    onSelectedCid(event: AutocompleteItem) {
        this.cidSelected = event;
        this.createInput.cid = event.text;
    }

    actionRemoveCertificateFile(o: any) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRemoveFile'), () => {
            this.loading.show();
            this.removeMedicalCertificateImageGQL.mutate({id: o.id}).subscribe(
                (data) => {
                    this.certificate.image = this.certificate.image.filter(x => x !== o);
                    this.loading.hide();
                }, err => super.onError(err));
        });
    }

    closeRevision() {
        this.route2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                location.href = '/home';
            } else {
                this.closeModal('modalRevision');
            }
        });
    }
}
