import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {User} from '../../generated/graphql';
import {UserService} from '../service/user.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserPermissionResolver implements Resolve<User> {
    constructor(private service: UserService,
                public router: Router) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<User> | Promise<User> | User {
        const user = this.service.getUserLocal();
        if (user.profile === 'admin') {
            return user;
        } else {
            this.router.navigate(['/logout']);
            return null;
        }
    }
}
