<ngx-spinner
        bdColor="rgba(3,46,88,1)"
        size="medium"
        color="#fff"
        type="ball-spin"
>
    <ng-container *ngIf="!this.isNullOrUndefined(this.loadingMenssage)">
        <span style="font-size: 20px; color: white;text-align: center">{{'please'| translate}}</span>
        <p style="font-size: 20px; color: white;text-align: center">{{this.loadingMenssage}}</p>
    </ng-container>
</ngx-spinner>
<section class="single-page">
    <div class="btn-hero" (click)="showModalUser()">
        <svg-icon src="assets/svg/configuser.svg"></svg-icon>
        <span>{{'editarperfil'|translate}}</span>
    </div>
    <div class="btn-hero2" [routerLink]="'/certificate'" *ngIf="modelUser.profile === 'admin'">
        <svg-icon src="assets/svg/report.svg"></svg-icon>
        <span>{{'listaatestado'|translate}}</span>
    </div>
    <div class="btn-hero3" [routerLink]="'/user'" *ngIf="modelUser.profile === 'admin'">
        <svg-icon src="assets/svg/config.svg"></svg-icon>
        <span>{{'gestao'|translate}}</span>
    </div>
    <div class="hero">
        <img src="assets/images/background.png" alt="Clic"/>
    </div>

    <div class="list-featured">
        <div class="item">
            <a href="javascript:void('');" (click)="actionCreate('modalAtestado')">
                <svg-icon src="assets/svg/declaracaoexame.svg"></svg-icon>
                <span>{{'atestadomedico'|translate}}</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" (click)="actionCreate('modalDeclaracao')">
                <svg-icon src="assets/svg/camera.svg"></svg-icon>
                <span>{{'declaracaoconsulta'|translate}}</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void ('');" (click)="actionCreate('modalAcompanhante')">
                <svg-icon src="assets/svg/declaracaoacompanhante.svg"></svg-icon>
                <span>{{'declaracaoacompanhante'|translate}}</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" (click)="actionCreate('modalMaternidade')">
                <svg-icon src="assets/svg/maternidade.svg"></svg-icon>
                <span>{{'licencamaternidade'|translate}}</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" (click)="actionCreate('modalDoacao')">
                <svg-icon src="assets/svg/blood.svg"></svg-icon>
                <span>{{'doacaodesangue'|translate}}</span>
            </a>
        </div>
    </div>

</section>

<section class="home" *ngIf="false">
    <div class="home_header">
        <h2 class="page_title">{{'atestadomedico'|translate}}| <span>Dashboard</span></h2>
        <div class="home_divider">&nbsp;</div>
    </div>
</section>
<section style="background: #f5f3ec;" class="home">

    <h2 class="text-primary"><b>{{'meusatestados'|translate}}</b></h2>
    <div class="row">
        <div class="col-12 col-md-8">
            <div class="row">
                <div class="col-12 col-md-3 mt-2 mb-3">
                    <div class="input-material mb-0">
                        <input class="form-control picker-period" type="text" required/>
                        <label>
                            <span>{{'periodo'|translate}}</span>
                            <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-3 mt-2">
                    <div class="input-material select">
                        <select id="type" [(ngModel)]="searchInput.type" class="form-control" required>
                            <option disabled value hidden selected></option>
                            <option [ngValue]="null">{{'todos'|translate}}</option>
                            <option *ngFor="let i of enumType" [value]="i">{{getTranslate(i)}}</option>
                        </select>
                        <label for="type">{{'tipo'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-3 mt-2">
                    <div class="input-material select">
                        <select id="status" [(ngModel)]="searchInput.status" class="form-control" required>
                            <option disabled value hidden selected></option>
                            <option [ngValue]="null">{{'todos'|translate}}</option>
                            <option *ngFor="let i of enumStatus" [value]="i">{{getTranslate(i)}}</option>
                        </select>
                        <label for="status">{{'status'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-2 mt-2 mb-2 ">
                    <a (click)="onSearch()" class="btn btn-primary btn-lg text-white">{{'buscar'|translate}}</a>
                </div>
            </div>
            <div class="p-3 bg-white table-responsive">
                <table class="table table-striped table_regular bg-white p-3">
                    <thead>
                    <tr>
                        <th scope="col">{{'criacao'|translate}}</th>
                        <th scope="col">{{'tipo'|translate}}</th>
                        <th class="text-center" scope="col">{{'dataatendimento'|translate}}</th>
                        <th class="text-center" scope="col">Status</th>
                        <th class="text-center">Status SAP</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(certPage?.results)">
                        <td colspan="4" class="text-center py-4">{{'nenhumainfo'|translate}}</td>
                    </tr>
                    <ng-container *ngFor="let c of certPage?.results |
                paginate: {itemsPerPage: certPage.pageSize,
                currentPage: certPage.currentPage,
                totalItems: certPage.totalCount };let i = index">
                        <tr class="is-cursor" (click)="resumeCertificate(c)">
                            <td><b>{{c.createdAt|date:'dd/MM/yyyy'}}</b></td>
                            <td><b>{{getTranslate(c.type)}}</b></td>
                            <td class="text-center"><b>{{c.appointmentDate|date:'dd/MM/yyyy'}}</b></td>
                            <td class="text-center">
                                <div class="badge badge-custom"
                                     [ngClass]="{
                                    'badge-gray' : (c.status === 'received'),
                                    'badge-warning' : (c.status === 'review'),
                                    'badge-success' : (c.status === 'approved'),
                                    'badge-danger' : (c.status === 'reproved')
                                }">{{ getTranslate(c.status)}}</div>
                            </td>
                            <td class="text-center">
                                <svg-icon *ngIf="c.exportSap" src="assets/svg/check-sap.svg"></svg-icon>
                            </td>
                        </tr>
                    </ng-container>

                    </tbody>
                </table>
            </div>
            <div class="text-right">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>

        </div>
        <div class="col-12 offset-md-1 col-md-3">
            <div class="attention">
                <div class="d-flex align-items-center">
                    <ng-lottie class="mb-3"
                               width="80px" [options]="attentionAnimation"></ng-lottie>
                    <h2 class="text-primary"><b>{{'atencao'|translate}}</b></h2>
                </div>
                <div class="divider"></div>
                <p class="px-4">
                    {{'homeHighlightText'|translate}}
                </p>
                <span *ngIf="modelUser?.acceptTerms" class="svg-success">
                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                </span>
                <button (click)="acceptTerms()" *ngIf="!modelUser?.acceptTerms"
                        class="btn btn-outline-primary  w-80 mt-4" tabindex="0">
                    <span class="pl-2">{{'okestouciente'|translate}}</span>
                </button>
            </div>
        </div>
    </div>
</section>


<!--Modal User -->
<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'editarusuario'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <div style="padding: 10px 40px;" class="row col-12 justify-content-center align-items-center">
                            <div class="col-12 col-md-6 d-flex justify-content-center">

                                <div style="position: relative"
                                     *ngIf="!isNullOrUndefined(croppedImage)||!isNullOrUndefined(modelUser.photo)">
                                    <img class="mb-3"
                                         style="width: 108px;height: 108px;border-radius: 100%;border: 3px solid #fff;object-fit: cover"
                                         [src]="croppedImage?croppedImage:modelUser.photo"
                                         (click)="showModal('modalCropLogin')" alt="img"/>
                                    <svg-icon src="assets/svg/edit-user.svg"
                                              style="position: absolute;bottom: 19px;right: 8px;"></svg-icon>
                                </div>

                                <div style="position: relative"
                                     *ngIf="isNullOrUndefined(croppedImage)&& isNullOrUndefined(modelUser.photo)"
                                     (click)="showModal('modalCropLogin')">
                                    <ng-lottie class="mb-3"
                                               width="120px" [options]="usersAnimation"></ng-lottie>
                                    <svg-icon src="assets/svg/new-user.svg"
                                              style="position: absolute;bottom: 28px;right: 16px;"></svg-icon>

                                </div>
                            </div>
                            <div class="col-12 col-md-6 d-flex justify-content-center">
                                <a class="btn btn-primary btn-lg text-white w-80" [routerLink]="'/authenticate'">
                                    <p class="text-white m-0">{{'alterarsenha'|translate}}</p>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="nameUser" class="form-control" type="text" required maxlength="150"
                                       [(ngModel)]="modelUser.name"
                                />
                                <label for="nameUser">{{'nomecompleto'|translate}}</label>
                            </div>
                        </div>
                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="reUser" class="form-control" type="text" required maxlength="15"
                                       [(ngModel)]="modelUser.re"
                                />
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userCPF" class="form-control" type="text" required maxlength="150"
                                       [(ngModel)]="modelUser.cpf"
                                       [mask]="'000.000.000-00'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                />
                                <label for="userCPF">CPF</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="countryUser" (change)="changeUnties(countryFilter.id)" [disabled]="true"
                                        [(ngModel)]="countryFilter.id" class="form-control"
                                        name="type" required>
                                    <option [ngValue]="null">{{'select-default'|translate}}</option>
                                    <option *ngFor="let i of countries"
                                            [ngValue]="i.id">{{i.name}}</option>
                                </select>
                                <label for="countryUser" class="mb-0 mr-3" [ngClass]="{'disabled' : true}">País</label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="unityUser" [(ngModel)]="unityId" class="form-control" name="type"
                                        [disabled]="true"
                                        required>
                                    <option [ngValue]="null">{{'select-default'|translate}}</option>
                                    <option *ngFor="let i of unities"
                                            [ngValue]="i.id">{{i.name}}</option>

                                </select>
                                <label for="unityUser" class="mb-0 mr-3"
                                       [ngClass]="{'disabled' : true}">{{'unidade'|translate}}</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userPhone" class="form-control" type="text"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                       [(ngModel)]="modelUser.phone"
                                       required maxlength="150"/>
                                <label for="userPhone">{{'telefonerecado'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userPhoneA" class="form-control" type="text"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       [dropSpecialCharacters]="false"
                                       [clearIfNotMatch]="true"
                                       [(ngModel)]="modelUser.cellphone"
                                       required maxlength="150"/>
                                <label for="userPhoneA">{{'celularpessoal'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="userEmail" class="form-control"
                                       [(ngModel)]="modelUser.email"
                                       required maxlength="150"/>
                                <label for="userEmail">{{'emailpessoal'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onUpdateUser()" class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">{{'salvar'|translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Revisão -->
<div class="modal-custom modal fade" id="modalRevision" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-extra modal-dialog-centered" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{getTranslate(this.createInput.type)}}
                    </b>
                    <span class="badge badge-custom ml-2"
                          [ngClass]="{
                                    'badge-gray' : (certificate.status === 'received'),
                                    'badge-warning' : (certificate.status === 'review'),
                                    'badge-success' : (certificate.status === 'approved'),
                                    'badge-danger' : (certificate.status === 'reproved')
                                }">{{ getTranslate(certificate.status)}}</span>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeRevision()">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <!--FORM-->
                        <div class="col-12 col-lg-7 d-flex flex-column justify-content-between">
                            <div class="d-flex flex-column">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="page-card secondary d-flex flex-column">
                                            <div class=" d-flex justify-content-between align-items-center">
                                                <h2 class="page-title tertiary">{{'sapcertificate'|translate}}</h2>
                                                <label class="switch">
                                                    <input type="checkbox" name="checkbox"
                                                           [checked]="certificate.exportSap"
                                                           [disabled]="true" [readOnly]="true">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="input-material">
                                            <input id="reviewRE" [(ngModel)]="createInput.re" class="form-control"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewRE">{{'recolaborador'|translate}}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.emissionDate)">
                                        <div class="input-material">
                                            <input id="reviewEmissionDate" class="form-control modalEmissionDate"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   [disabled]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewEmissionDate">{{'dataemissao'|translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.appointmentType)">
                                        <div class="input-material">
                                            <!--<input id="reviewAppointmentType" [(ngModel)]="createInput.appointmentType" class="form-control" type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   required maxlength="15"/>-->
                                            <select id="reviewAppointmentType" [(ngModel)]="createInput.appointmentType"
                                                    class="form-control"
                                                    [disabled]="reviewReadOnly"
                                                    required>
                                                <option disabled value hidden selected></option>
                                                <ng-container *ngIf="createInput.type != 'consult'">
                                                    <option *ngFor="let i of enumType"
                                                            [value]="i">{{getTranslate(i)}}</option>
                                                </ng-container>
                                                <ng-container *ngIf="createInput.type == 'consult'">
                                                    <option *ngFor="let i of enumTypeConsult"
                                                            [value]="i">{{getTranslate(i)}}</option>
                                                </ng-container>

                                            </select>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewAppointmentType">{{'tipoatendimento'|translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.timeOff)">
                                        <div class="input-material select">

                                            <select id="reviewtimeOff" [(ngModel)]="createInput.timeOff"
                                                    class="form-control"
                                                    [disabled]="reviewReadOnly"
                                                    required>
                                                <option disabled value hidden selected></option>
                                                <option *ngFor="let i of days" [ngValue]="i.value">{{i.text}}</option>
                                            </select>

                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewtimeOff">{{'atendimento'|translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.certificateOwner)">
                                        <div class="input-material">
                                            <select id="reviewOwner" [(ngModel)]="createInput.certificateOwner"
                                                    class="form-control"
                                                    required>
                                                <option disabled value hidden selected></option>
                                                <option *ngFor="let i of enumPerson"
                                                        [value]="i">{{getTranslate(i)}}</option>
                                            </select>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewOwner">{{'pessoaatestada'|translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.appointmentStart)">
                                        <div class="input-material">
                                            <input id="reviewAppointmentStart"
                                                   class="form-control modalAppointmentStart" type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   [disabled]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewAppointmentStart">{{'saida'|translate}}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.appointmentEnd)">
                                        <div class="input-material">
                                            <input id="reviewAppointmentEnd" class="form-control modalAppointmentEnd"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   [disabled]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewAppointmentEnd">{{'retorno'|translate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.crm)">
                                        <div class="input-material">
                                            <input id="reviewCrm" [(ngModel)]="createInput.crm" class="form-control"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}"
                                                   for="reviewCrm">CRM/CRO</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6"
                                         *ngIf="!isNullOrUndefined(certificate.uf)">
                                        <div class="input-material">
                                            <select id="reviewUf" [(ngModel)]="createInput.uf" class="form-control"
                                                    required
                                                    [disabled]="reviewReadOnly">
                                                <option disabled value hidden selected></option>
                                                <option *ngFor="let i of enumUF">{{i}}</option>
                                            </select>
                                            <label [ngClass]="{'disabled' : reviewReadOnly}" for="reviewUf">UF</label>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="!isNullOrUndefined(certificate.cid)">
                                        <div class="input-material">
                                            <input *ngIf="reviewReadOnly" id="reviewCid" [(ngModel)]="createInput.cid"
                                                   class="form-control"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   required maxlength="15"/>

                                            <cid-autocomplete *ngIf="!reviewReadOnly" [selected]="cidSelected"
                                                              (onSelected)="onSelectedCid($event)"></cid-autocomplete>

                                            <label class="disabled" for="reviewCid">CID</label>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="!isNullOrUndefined(certificate.observation)">
                                        <div class="input-material">
                                            <input *ngIf="reviewReadOnly" id="reviewCid"
                                                   [(ngModel)]="createInput.observation"
                                                   class="form-control"
                                                   type="text"
                                                   [readOnly]="reviewReadOnly"
                                                   required maxlength="15"/>
                                            <label class="disabled" for="reviewCid">Observação</label>
                                        </div>
                                    </div>
                                </div>
                                <ng-container
                                        *ngIf="isNullOrUndefined(certificate.status) || certificate.status === 'review'">
                                    <div class="divider p-3"></div>
                                    <div class="row justify-content-between p-3">
                                        <div class="col-md-6 col-12">
                                            <p class="text m-0">{{'tireouadicione'|translate}}</p>
                                        </div>
                                        <div class="col-md-6 col-12">
                                            <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                                    style="padding: 11px 13px;">
                                                <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row p-3" style="width: 100%;">
                                        <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                            <div style="position: relative">
                                                <img [src]="img" alt="img">
                                                <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                                          src="assets/svg/close-circle.svg"></svg-icon>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="divider"></div>
                                <div class="">
                                    <p class="page-lbl">{{'anexosenviados'|translate}}</p>
                                    <div class="row">
                                        <div class="w-100 text-center d-block" *ngIf="certificate?.image?.length === 0">
                                            <small class="text-center py-5">{{'nenhumarquivoencontrado'|translate}}</small>
                                        </div>
                                        <div *ngFor="let img of certificate.image"
                                             class="col-3 d-flex flex-column justify-content-center align-items-center mb-2"
                                             style="gap: 15px">
                                            <div style="position: relative">
                                                <img class="page-upload img"
                                                     src="{{getUrlCertificateImage(img.url)}}"
                                                     alt="certificate"/>
                                                <svg-icon (click)="actionRemoveCertificateFile(img)"
                                                          *ngIf="certificate.status === 'review'"
                                                          style="position:absolute;right: 5px;"
                                                          src="assets/svg/close-circle.svg"></svg-icon>
                                            </div>

                                            <a href="{{img.url}}"
                                               target="_blank" class="btn btn-primary text-white">
                                                <svg-icon class="mr-2" src="assets/svg/download.svg"></svg-icon>
                                                Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between">
                                <a data-dismiss="modal" aria-label="Close"
                                   class="btn btn-outline-primary mt-5 mr-2 flex-grow-1">{{'voltar'|translate}}</a>
                                <a (click)="onSaveReview()"
                                   *ngIf="modelUser.profile === 'user' && certificate.status === 'review'"
                                   class="btn btn-primary mt-5 ml-2 flex-grow-1">{{'salvar'|translate}}</a>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <div>
                                <div class="page-card tertiary w-100" style="height: inherit;height: 510px">
                                    <h2 class="page-title flex-grow-0 mb-4">{{'comentarios'|translate}}</h2>
                                    <div class="flex-grow-1">
                                        <ng-container *ngIf="isNullOrUndefined(certificate.comment)">
                                            <div class="flex-grow-1">
                                                <div class="d-flex justify-content-center">
                                                    <ng-lottie width="300px" height="initial"
                                                               [options]="message"></ng-lottie>
                                                </div>
                                                <p class="page-lbl comments"> {{'emcasodeduvidas'|translate}}</p>
                                            </div>

                                        </ng-container>
                                        <div class="page-chat">
                                            <ng-container *ngIf="!isNullOrUndefined(certificate.comment)">
                                                <div class="page-chat">
                                                    <ng-container *ngFor="let i of certificate.comment">
                                                        <div class="d-flex"
                                                             [ngClass]="{'flex-row-reverse':i.createdBy.id == this.userId}"
                                                             style="gap: 20px">
                                                            <img *ngIf="!isNullOrUndefined(i.createdBy.photo)"
                                                                 src="{{i.createdBy.photo}}"
                                                                 alt="person"
                                                                 class="page-person">
                                                            <ng-lottie *ngIf="isNullOrUndefined(i.createdBy.photo)"
                                                                       class="mb-3"
                                                                       width="35px"
                                                                       [options]="usersAnimation"></ng-lottie>

                                                            <div class="bubbly"
                                                                 [ngClass]="[ (i.createdBy.id != this.userId ? 'sb1' : 'sb2')]">
                                                                <p class="bubbly-name">{{i.createdBy.name}}</p>
                                                                <p class="bubbly-text">{{i.comment}}</p>
                                                                <p class="bubbly-date">{{i.createdAt |date:'dd/MM'}}</p>
                                                            </div>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="chat-wrapper" *ngIf="certificate.status == 'review'">
                                        <textarea autosize minRows="1" maxRows="6"
                                                  [(ngModel)]="this.createCommentInput.comment"
                                                  class="message-text"
                                                  placeholder="Escreva aqui"></textarea>
                                        <svg-icon class="is-cursor" (click)="actionComments(certificate.id)"
                                                  src="assets/svg/send.svg"></svg-icon>
                                    </div>
                                </div>
                                <div  *ngIf="modelUser.profile === 'admin'">
                                    <div class="d-flex flex-grow-1" style="column-gap: 31px;row-gap: 21px">
                                        <a (click)="onApproved(certificate.id)"
                                           class="btn btn-primary flex-grow-1">{{'aprovar'|translate}}</a>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="certificate.status == 'reproved'">
                                <div class="page-card secondary mb-0">
                                    <h2 class="page-title tertiary">Justificativa da reprova</h2>
                                    <p class="page-text">{{certificate.reproveJustification}}</p>
                                    <div class="page-divider my-3">&nbsp;</div>
                                    <div class="d-flex align-items-center" style="gap: 15px">
                                        <img [src]="certificate.createdBy.photo?certificate.createdBy.photo:'assets/images/person-1.png'"
                                             alt="person"
                                             class="page-person">
                                        <p class="page-text flex-grow-1">{{certificate.reprovedBy.name}}</p>
                                        <p class="page-text">{{certificate.createdAt | date:'dd/MM'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- COUNTRY DROPDOWN -->

                        <!-- UNITY DROPDOWN -->

                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Atestado -->
<div class="modal-custom modal fade" id="modalAtestado" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'atestadomedico'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">

                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="createRe" [(ngModel)]="createInput.re" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="createRe">{{'recolaborador'|translate}}</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" class="form-control emissionDate" required>
                                <label>
                                    <span>{{'dataemissao'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" class="form-control appointmentDate" required>
                                <label><span>{{'dataatendimento'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material select">
                                <!--                                <input id="createTimeoff" [(ngModel)]="createInput.timeOff" class="form-control"-->
                                <!--                                       [mask]="'Hh:m0'"-->
                                <!--                                       [dropSpecialCharacters]="false"-->
                                <!--                                       type="text" required maxlength="150"/>-->

                                <select id="createTimeoff" [(ngModel)]="createInput.timeOff" class="form-control"
                                        required>
                                    <option disabled value hidden selected></option>
                                    <option *ngFor="let i of days" [ngValue]="i.value">{{i.text}}</option>
                                </select>

                                <label for="createTimeoff">{{'tempoatendimento'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-9">
                            <div class="input-material">
                                <input id="createCRM" [(ngModel)]="createInput.crm" mask="00000000-0"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">CRM/CRO</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 ">
                            <div class="input-material select">
                                <select id="typeUF" [(ngModel)]="createInput.uf" class="form-control" required>
                                    <option disabled value hidden selected></option>
                                    <option *ngFor="let i of enumUF">{{i}}</option>
                                </select>
                                <label for="typeUF">UF</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material input_padding">
                                <cid-autocomplete [selected]="cidSelected"
                                                  (onSelected)="onSelectedCid($event)"></cid-autocomplete>
                                <label class="disabled">CID</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="createObservation" [(ngModel)]="createInput.observation"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">Observação</label>
                            </div>
                        </div>
                        <div class="divider p-3"></div>
                        <div class="row justify-content-between p-3">
                            <div class="col-md-6 col-12">
                                <p class="text m-0">{{'tireouadicione'|translate}}</p>
                            </div>
                            <div class="col-md-6 col-12">
                                <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                        style="padding: 11px 13px;">
                                    <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-3" style="width: 100%;">
                            <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                <div style="position: relative">
                                    <img [src]="img" alt="img">
                                    <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                              src="assets/svg/close-circle.svg"></svg-icon>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 my-3">
                            <button (click)="onConfirm('modalAtestado')"
                                    class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">{{'salvar'|translate}}</span>
                            </button>
                        </div>

                        <!-- COUNTRY DROPDOWN -->

                        <!-- UNITY DROPDOWN -->

                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Declaracao -->
<div class="modal-custom modal fade" id="modalDeclaracao" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'declaracaoconsulta'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">

                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="reUsers" [(ngModel)]="createInput.re" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="reUsers">{{'recolaborador'|translate}}</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required class="form-control appointmentDate">
                                <label>
                                    <span>{{'dataatendimento'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ">
                            <div class="input-material select">
                                <select id="appointmentType" [(ngModel)]="createInput.appointmentType"
                                        class="form-control"
                                        required>
                                    <option disabled value hidden selected></option>
                                    <option *ngFor="let i of enumTypeConsult" [value]="i">{{getTranslate(i)}}</option>
                                </select>
                                <label for="appointmentType">{{'tipoatendimento'|translate}}</label>
                            </div>
                        </div>

                        <!-- UNITY DROPDOWN -->
                        <div class="col-12">
                            <b>{{'periodoatendimento'|translate}}</b>
                        </div>

                        <div class="col-12 col-md-6 mt-2 mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required class="form-control appointmentStart">
                                <label>
                                    <span>{{'inicio'|translate}}</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2 mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required class="form-control appointmentEnd">
                                <label>
                                    <span>{{'fim'|translate}}</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="createObservation" [(ngModel)]="createInput.observation"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">Observação</label>
                            </div>
                        </div>
                        <div class="divider p-3"></div>
                        <div class="row justify-content-between p-3">
                            <div class="col-md-6 col-12">
                                <p class="text m-0">{{'tireouadicione'|translate}}</p>
                            </div>
                            <div class="col-md-6 col-12">
                                <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                        style="padding: 11px 13px;">
                                    <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-3" style="width: 100%;">
                            <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                <div style="position: relative">
                                    <img [src]="img" alt="img">
                                    <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                              src="assets/svg/close-circle.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onConfirm('modalDeclaracao')"
                                    class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">{{'salvar'|translate}}</span>
                            </button>
                        </div>

                        <!-- COUNTRY DROPDOWN -->

                        <!-- UNITY DROPDOWN -->

                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Acompanhante -->
<div class="modal-custom modal fade" id="modalAcompanhante" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'declaracaoacompanhante'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="col-12">
                        <div class="d-flex align-items-center"  style="justify-content: center">
                            <ng-lottie width="80px" [options]="attentionAnimation"></ng-lottie>
                            <h4 class="text-primary"><b>{{'atencao'|translate}}</b></h4>
                        </div>
                        <div class="d-flex align-items-center"  style="justify-content: center">
                            <h6 class="text-primary">
                                <strong>{{'limitQtd'|translate}}</strong>
                            </h6>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="row my-3">

                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="createRE3" [(ngModel)]="createInput.re" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="createRE3">{{'recolaborador'|translate}}</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control emissionDate">
                                <label>
                                    <span>{{'dataemissao'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ">
                            <div class="input-material select">
                                <select id="appointmentType2" [(ngModel)]="createInput.appointmentType"
                                        class="form-control"
                                        required>
                                    <option disabled value hidden selected></option>
                                    <option *ngFor="let i of enumTypeAcompanhante"
                                            [value]="i">{{getTranslate(i)}}</option>
                                </select>
                                <label for="appointmentType2">{{'tipoatendimento'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material select">
                                <select id="certificateOwner" [(ngModel)]="createInput.certificateOwner"
                                        [disabled]="isNullOrUndefined(createInput.appointmentType)"
                                        class="form-control"
                                        required>
                                    <option disabled value hidden selected></option>

                                    <ng-container *ngIf="createInput.appointmentType === 'hospitalization'">
                                        <option *ngFor="let i of enumPerson1" [value]="i">{{getTranslate(i)}}</option>
                                    </ng-container>

                                    <ng-container *ngIf="createInput.appointmentType === 'consult'">
                                        <option *ngFor="let i of enumPerson2" [value]="i">{{getTranslate(i)}}</option>
                                    </ng-container>

                                    <ng-container *ngIf="createInput.appointmentType === 'complexExam'">
                                        <option *ngFor="let i of enumPerson3" [value]="i">{{getTranslate(i)}}</option>
                                    </ng-container>

                                </select>
                                <label for="certificateOwner">{{'pessoaatestada'|translate}}</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-12">
                            <b>{{'periodoacompanhamento'|translate}}</b>
                        </div>

                        <div class="col-12 col-md-6 mt-2 mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control appointmentStart">
                                <label>
                                    <span>{{'inicio'|translate}}</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2 mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control appointmentEnd">
                                <label>
                                    <span>Fim</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right"></svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="createObservation" [(ngModel)]="createInput.observation"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">Observação</label>
                            </div>
                        </div>

                        <div class="divider p-3"></div>
                        <div class="row justify-content-between p-3">
                            <div class="col-md-6 col-12">
                                <p class="text m-0">{{'tireouadicione'|translate}}</p>
                            </div>
                            <div class="col-md-6 col-12">
                                <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                        style="padding: 11px 13px;">
                                    <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-3" style="width: 100%;">
                            <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                <div style="position: relative">
                                    <img [src]="img" alt="img">
                                    <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                              src="assets/svg/close-circle.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onConfirm('modalAcompanhante')"
                                    class="btn btn-primary btn-lg text-white w-100"><p
                                    class="text-white m-0">{{'salvar'|translate}}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Maternidade -->
<div class="modal-custom modal fade" id="modalMaternidade" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'licencamaternidade'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">

                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="createRE4" [(ngModel)]="createInput.re" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="createRE4">{{'recolaborador'|translate}}</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control emissionDate">
                                <label>
                                    <span>{{'dataemissao'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control timeOffStartDate">
                                <label>
                                    <span>{{'dataafastamento'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->

                        <div class="col-12 col-md-9">
                            <div class="input-material">
                                <input id="createCRM3" [(ngModel)]="createInput.crm" class="form-control" type="text"
                                       required mask="00000000-0"/>
                                <label for="createCRM3">CRM</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 ">
                            <div class="input-material select">
                                <select id="createUF3" [(ngModel)]="createInput.uf" class="form-control" required>
                                    <option disabled value hidden selected></option>
                                    <option *ngFor="let i of enumUF">{{i}}</option>
                                </select>
                                <label for="createUF3">UF</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="createObservation" [(ngModel)]="createInput.observation"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">Observação</label>
                            </div>
                        </div>

                        <div class="divider p-3"></div>
                        <div class="row justify-content-between p-3">
                            <div class="col-md-6 col-12">
                                <p class="text m-0">{{'tireouadicione'|translate}}</p>
                            </div>
                            <div class="col-md-6 col-12">
                                <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                        style="padding: 11px 13px;">
                                    <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-3" style="width: 100%;">
                            <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                <div style="position: relative">
                                    <img [src]="img" alt="img">
                                    <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                              src="assets/svg/close-circle.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onConfirm('modalMaternidade')"
                                    class="btn btn-primary btn-lg text-white w-100"><p
                                    class="text-white m-0">{{'salvar'|translate}}</p>
                            </button>
                        </div>

                        <!-- COUNTRY DROPDOWN -->

                        <!-- UNITY DROPDOWN -->

                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Doacao -->
<div class="modal-custom modal fade" id="modalDoacao" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'doacaodesangue'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">

                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="createRE5" [(ngModel)]="createInput.re" class="form-control" type="text"
                                       required maxlength="15"/>
                                <label for="createRE5">{{'recolaborador'|translate}}</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required class="form-control emissionDate">
                                <label>
                                    <span>{{'dataemissao'|translate}}</span>
                                    <svg-icon src="assets/svg/calendar.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <b>{{'periododedoacao'|translate}}</b>
                        </div>
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control appointmentStart">
                                <label>
                                    <span>{{'inicio'|translate}}</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6  mb-3">
                            <div class="input-material mb-0">
                                <input type="text" required="" class="form-control appointmentEnd">
                                <label>
                                    <span>Fim</span>
                                    <svg-icon src="assets/svg/clock.svg" class="right">
                                    </svg-icon>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input id="createObservation" [(ngModel)]="createInput.observation"
                                       class="form-control"
                                       type="text"
                                       required/>
                                <label for="createCRM">Observação</label>
                            </div>
                        </div>
                        <div class="divider p-3"></div>
                        <div class="row justify-content-between p-3">
                            <div class="col-md-6 col-12">
                                <p class="text m-0">{{'tireouadicione'|translate}}</p>
                            </div>
                            <div class="col-md-6 col-12">
                                <button (click)="showModal('modalAttachment')" class="btn btn-warning"
                                        style="padding: 11px 13px;">
                                    <span class="text-primary m-0"> {{'adicionar'|translate}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-3" style="width: 100%;">
                            <div class="col-3" *ngFor="let img of croppedAttachment,let i = index">
                                <div style="position: relative">
                                    <img [src]="img" alt="img">
                                    <svg-icon (click)="removeFile(i)" style="position:absolute;right: 5px;"
                                              src="assets/svg/close-circle.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onConfirm('modalDoacao')" class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">{{'salvar'|translate}}</span>
                            </button>
                        </div>

                        <!-- COUNTRY DROPDOWN -->

                        <!-- UNITY DROPDOWN -->

                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal de confirmação -->
<div class="modal-custom modal fade" id="modalConfirm" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 454px">
        <div class="modal-content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{'confirmedados'|translate}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn modal_close"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="" style="min-height: inherit;">
                <div class="p-4">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-wrap" style="column-gap: 15px;">
                            <div class="flex-grow-1">
                                <p class="page-lbl">{{'recolaborador' | translate}}</p>
                                <p class="page-value">{{createInput.re}}</p>
                            </div>
                            <div *ngIf="!isNullOrUndefined(createInput.emissionDate)" class="flex-grow-1">
                                <p class="page-lbl">{{'dataemissao'|translate}}</p>
                                <p class="page-value">{{createInput.emissionDate|date:'dd/MM/yyyy'}}</p>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap" style="column-gap: 15px;">
                            <div *ngIf="!isNullOrUndefined(createInput.appointmentType)" class="flex-grow-1">
                                <p class="page-lbl">{{'tipoatendimento'|translate}}</p>
                                <p class="page-value">{{getTranslate(createInput.appointmentType)}}</p>
                            </div>
                            <div *ngIf="!isNullOrUndefined(createInput.timeOff)" class="flex-grow-1">
                                <p class="page-lbl">{{'tempoatendimento'|translate}}</p>
                                <p class="page-value">{{createInput.timeOff}} Dia(s)</p>
                            </div>
                            <div *ngIf="!isNullOrUndefined(createInput.appointmentDate)" class="flex-grow-1">
                                <p class="page-lbl">{{'dataatendimento'|translate}}</p>
                                <p class="page-value">{{createInput.appointmentDate|date:'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap" style="column-gap: 15px;">
                            <div *ngIf="!isNullOrUndefined(createInput.certificateOwner)" class="flex-grow-1">
                                <p class="page-lbl">{{'pessoaatestada'|translate}}</p>
                                <p class="page-value">{{getTranslate(createInput.certificateOwner)}}</p>
                            </div>
                        </div>


                        <div class="d-flex flex-wrap" style="column-gap: 15px;">

                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.appointmentStart)">
                                <p class="page-lbl lbl-lg">{{'saida'|translate}}</p>
                                <p class="page-value">
                                    <svg-icon class="mr-2" src="assets/svg/calendar.svg"></svg-icon>
                                    {{createInput.appointmentStart |date:'dd/MM/yyyy' : 'UTC'}}
                                </p>
                                <p class="page-value">
                                    <svg-icon class="mr-2" src="assets/svg/clock.svg"></svg-icon>
                                    {{createInput.appointmentStart |date:'HH:mm' : 'UTC'}}
                                </p>
                            </div>
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.appointmentEnd)">
                                <p class="page-lbl lbl-lg">{{'retorno'|translate}}</p>
                                <p class="page-value">
                                    <svg-icon class="mr-2" src="assets/svg/calendar.svg"></svg-icon>
                                    {{createInput.appointmentEnd |date:'dd/MM/yyyy' : 'UTC'}}
                                </p>
                                <p class="page-value">
                                    <svg-icon class="mr-2" src="assets/svg/clock.svg"></svg-icon>
                                    {{createInput.appointmentEnd |date:'HH:mm' : 'UTC'}}
                                </p>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap" style="column-gap: 15px;">
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.crm)">
                                <p class="page-lbl">CRM</p>
                                <p class="page-value">{{createInput.crm}}</p>
                            </div>
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.uf)">
                                <p class="page-lbl">UF</p>
                                <p class="page-value">{{createInput.uf}}</p>
                            </div>
                        </div>
                        <div class="flex-wrap mt-4">
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.cid)">
                                <p class="page-lbl">CID</p>
                                <p class="page-value">{{createInput.cid}}</p>
                            </div>
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.timeOffStartDate)">
                                <div class="flex-grow-1">
                                    <p class="page-lbl">{{'dataafastamento' | translate}}</p>
                                    <p class="page-value">{{createInput.timeOffStartDate|date:'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap mt-4">
                            <div class="flex-grow-1" *ngIf="!isNullOrUndefined(createInput.observation)">
                                <div class="flex-grow-1">
                                    <p class="page-lbl">{{'observação'}}</p>
                                    <p class="page-value">{{createInput.observation}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <div>
                            <p class="page-lbl">{{'anexo'|translate}}</p>
                            <div class="row">
                                <div class="col-4" *ngFor="let img of croppedAttachment">
                                    <img class="page-upload img img_little"
                                         [src]="img"
                                         alt="certificate"/>
                                </div>
                                <div class="col-4" *ngFor="let img of certificate.image">
                                    <img class="page-upload img img_little"
                                         [src]="img.url"
                                         alt="certificate"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-5" style="gap: 20px">
                        <a (click)="closeModal('modalConfirm')"
                           class="btn flex-grow-1 btn-outline-primary">{{'voltar'|translate}}</a>
                        <a (click)="onSave()" class="btn flex-grow-1 btn-primary">{{'enviar'|translate}}</a>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="3/4"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

<app-crop-image [cropId]="'modalAttachment'"
                [cropAspectRatio]="null"
                [cropResizeToWidth]="null"
                [cropResizeToHeight]="null"
                (croppedImageResult)="setCroppedImageAttachment($event)"></app-crop-image>
