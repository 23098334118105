import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-alarm',
  templateUrl: './settings-alarm.component.html',
  styleUrls: ['./settings-alarm.component.scss']
})
export class SettingsAlarmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
