import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-alarm-home',
    templateUrl: './alarm-home.component.html',
    styleUrls: ['./alarm-home.component.scss']
})
export class AlarmHomeComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    UpdateUser(list: number) {

    }

    actionDelete(id: any) {

    }
}
