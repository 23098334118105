<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item  d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link text-white sidebar_top"
               style="height: initial;"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      BIO
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
                    <img src="{{!user?.photo ? 'assets/images/user.png' : user?.photo}}"
                         class="profile_photo"/>
                  </span>
                </span>
            </a>
        </li>

        <li class="nav-item">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link align-items-center" *ngIf="user.profile === 'admin'"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>
<!--        <li class="nav-item">-->
<!--            <a href="javascript:void('Nota');" [routerLink]="['/dashboard']"-->
<!--               class="nav-link text-white align-items-center"-->
<!--               style="gap: 20px"-->
<!--               (click)="actionCloseCollapse()">-->
<!--                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>-->
<!--                <span class="lbl">Dashboard</span>-->
<!--            </a>-->
<!--        </li>-->
        <li class="nav-item">
            <a href="javascript:void('Nota');" [routerLink]="['/certificate']" *ngIf="user.profile === 'admin'"
               class="nav-link text-white align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/feed_black.svg"></svg-icon>

                <span class="lbl">Lista de Atestados</span>
            </a>
        </li>


        <li class="nav-item" *ngIf="user.profile === 'admin'">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#setting" aria-expanded="false"
               aria-controls="setting">
                <svg-icon src="assets/svg/settings.svg"></svg-icon>
                <span class="lbl">Gestão</span>
            </a>
            <div class="collapse" id="setting" data-parent="#accordionExample">
                <a href="javascript:void(0);" [routerLink]="['/user']"
                   class="nav-link text-white align-items-center">
                    <svg-icon class="mr-2" src="assets/svg/usuario.svg"></svg-icon>
                    <span class="lbl">Usuários</span>
                </a>
                <a href="javascript:void(0);" [routerLink]="['/admin']" (click)="actionCloseCollapse()"
                   class="nav-link text-white align-items-center">
                    <svg-icon class="mr-2" src="assets/svg/usuario.svg"></svg-icon>
                    <span class="lbl">Administrativo</span>
                </a>
            </div>
        </li>


    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" [routerLink]="['/logout']">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
