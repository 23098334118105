import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    GetUnitiesGQL, MedicalCertificatePaginator,
    SearchMedicalCertificateGQL,
    SearchMedicalCertificateInput, SearchMedicalCertificateQuery,
    Unity
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ExcelService} from '../../service/excel.service';
import {StatusFile} from "./model/status-file";
import moment from "moment";

declare var $;

@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent extends BaseComponent implements OnInit, AfterViewInit {
    searchMedicalCertificateQuery: QueryRef<SearchMedicalCertificateQuery>;
    searchMedicalCertificateInput: SearchMedicalCertificateInput = new SearchMedicalCertificateInput();

    certificatesPage: MedicalCertificatePaginator = new MedicalCertificatePaginator();
    unities: Unity[] = [];

    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    statusFile: StatusFile = new StatusFile();
    stopProgress: Boolean;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public searchMedicalCertificateGQL: SearchMedicalCertificateGQL,
                public excelService: ExcelService,
                public getUnitiesGQL: GetUnitiesGQL,
    ) {
        super(router, loading, translate);
    }

    ngAfterViewInit(): void {
        this.getUnitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.unities = data.getUnities as Unity[];
        }, error => super.onError(error));

        this.searchMedicalCertificateInput.start = moment().subtract(30, 'days').utcOffset(0, true).startOf('day').format();
        this.searchMedicalCertificateInput.end = moment().utcOffset(0, true).endOf('day').format();
        this.actionList();
    }

    ngOnInit(): void {
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                console.log('url');
                console.log(url);
            }
        });
        this.setDatePicker('picker-period', (start, end, label) => {
            this.searchMedicalCertificateInput.start = start.utcOffset(0, true).format();
            this.searchMedicalCertificateInput.end = end.utcOffset(0, true).format();
        });
    }

    actionList() {
        this.searchMedicalCertificateQuery = this.searchMedicalCertificateGQL.watch({input: this.searchMedicalCertificateInput});
        this.searchMedicalCertificateQuery.valueChanges.subscribe(({data}) => {
            this.certificatesPage = data.searchMedicalCertificate as MedicalCertificatePaginator;
        }, error => super.onError(error));
    }

    onSearch() {
        this.loading.show();
        this.searchMedicalCertificateQuery.refetch({input: this.searchMedicalCertificateInput}).finally(() => {
            this.loading.hide();
        }).catch(error => super.onError(error));
    }


    actionExcel() {
        this.excelService.getExcelMedicalCertificate(this.searchMedicalCertificateInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    pageChanged(event) {
        this.searchMedicalCertificateInput.page = event;
        this.actionList();
    }

    onCheckZip() {
        this.excelService.medicalCertificateFile(this.searchMedicalCertificateInput).subscribe((data) => {
            console.log(data as any);
            const d = data as any;
            this.stopProgress = false;
            this.getCheckZip(d.id);
            this.showModal('modalCheckZip');
        }, ex => {
            super.onError(ex.error);
        })
    }

    getCheckZip(id: string) {
        if (!this.isNullOrUndefined(id)) {
            this.excelService.medicalCertificateCheck(id).subscribe((data) => {
                this.statusFile = data as StatusFile;
                console.log(this.statusFile);
                if (this.statusFile.status != 'done' && !this.stopProgress) {
                    setTimeout(() => {
                        this.getCheckZip(id);
                    }, 1000);
                }
            }, error => super.onError(error))
        }
    }
}
