<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'atestados'|translate}}<!-- | <span>Controle de acesso</span>--></h1>
            <a class="btn btn-isnfo d-none">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                {{'exportarsap'|translate}}
            </a>
            <div class="d-flex" style="gap: 20px">
                <a class="btn btn-gray-fill" (click)="actionExcel()">
                    <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                    {{'exportarexcel'|translate}}
                </a>
                <a class="btn btn-gray-fill" (click)="onCheckZip()">
                    <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                    {{'exportZip'|translate}}
                </a>
            </div>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="searchMedicalCertificateInput.keyword" class="form-control" type="text"
                       required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control picker-period" type="text" required/>
                <label for="date">
                    <span>{{'periodo'|translate}}</span>
                    <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                </label>
            </div>
            <div class="input-material select">
                <select id="unity" [(ngModel)]="searchMedicalCertificateInput.unity" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option *ngFor="let i of unities" [value]="i.id">{{i.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="Tipo" [(ngModel)]="searchMedicalCertificateInput.type" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option *ngFor="let i of enumType" [value]="i">{{getTranslate(i)}}</option>
                </select>
                <label for="Tipo">{{'tipo'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="status" [(ngModel)]="searchMedicalCertificateInput.status" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option *ngFor="let i of enumStatus" [value]="i">{{getTranslate(i)}}</option>
                </select>
                <label for="status">{{'status'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="SAP" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option>SAP 1</option>
                    <option>SAP 2</option>
                </select>
                <label for="status">SAP</label>
            </div>
            <a (click)="onSearch()" class="btn btn-primary btn-lg text-white">{{'buscar'|translate}}</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th>{{'buscar'|translate}}</th>
                    <th>{{'tipo'|translate}}</th>
                    <th>RE</th>
                    <th>{{'solicitante'|translate}}</th>
                    <th>CID</th>
                    <th>UF</th>
                    <th>{{'emissao'|translate}}</th>
                    <th class="text-center">{{'status'|translate}}</th>
                    <th class="text-center">Status SAP</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(certificatesPage?.results)">
                    <td colspan="9" class="text-center py-4">{{'nenhumainfo'|translate}}</td>
                </tr>

                <tr *ngFor="let list of certificatesPage?.results|
                paginate: {itemsPerPage: certificatesPage.pageSize,
                currentPage: certificatesPage.currentPage,
                totalItems: certificatesPage.totalCount }" class="is-cursor" [routerLink]="'/certificate/'+ list.id">
                    <td>
                        <div class="bar-status">
                            <div class="bar-status-item" [ngClass]="{
                                    'bg-gray' : (list.status === 'received'),
                                    'bg-warning' : (list.status === 'review'),
                                    'bg-success' : (list.status === 'approved'),
                                    'bg-danger' : (list.status === 'reproved')
                                }"></div>
                        </div>
                    </td>
                    <td>{{getTranslate(list.type)}}</td>
                    <td>{{list.re}}</td>
                    <td>{{list.ownerBy.name}}</td>
                    <td>{{list.cid}}</td>
                    <td>{{list.uf}}</td>
                    <td>{{list.emissionDate |date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <div class="badge badge-custom"
                             [ngClass]="{
                                    'badge-gray' : (list.status === 'received'),
                                    'badge-warning' : (list.status === 'review'),
                                    'badge-success' : (list.status === 'approved'),
                                    'badge-danger' : (list.status === 'reproved')
                                }">{{ getTranslate(list.status)}}</div>
                    </td>
                    <td class="text-center">
                        <svg-icon *ngIf="list.exportSap" src="assets/svg/check-sap.svg"></svg-icon>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalCheckZip" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document" style="max-width: 454px;">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray">
                <h2 class="modal-title">{{'exportZip'|translate}}</h2>
                <a (click)="this.stopProgress = true" data-dismiss="modal" class="modal_close ml-auto"
                   aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="d-flex flex-column align-items-center px-3 pb-3 pt-5">
                <div class="position-relative" *ngIf="this.statusFile.status !== 'done'">
                    <div style="transform: scale(2)">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <h6 style="top: 3px;left: 0;text-align: center;width: 100%;"
                        *ngIf="!isNullOrUndefined(this.statusFile.progress)"
                        class="text-primary position-absolute">
                        {{this.statusFile.progress!.split('.')[0]}}%
                    </h6>
                </div>
                <div *ngIf="this.statusFile.status === 'done'">
                    <h6 *ngIf="!isNullOrUndefined(this.statusFile.progress)" class="text-primary text-center mt-2">
                        "Seu arquivo zip foi concluído com sucesso. Obrigado!"
                    </h6>
                </div>
                <a target="_blank" href="{{this.statusFile.url}}" class="btn btn-block mt-4 btn-success text-white"
                   [ngClass]="{'disabled':this.statusFile.status !== 'done'}">{{'Download'|translate}}</a>
            </div>
        </div>
    </div>
</div>
