<div class="ng-autocomplete">
    <!--    {{_selected | json}}-->
    <ng-autocomplete
        #auto
        [data]="list"
        [searchKeyword]="'text'"
        placeholder="Informe o CID"
        (selected)='selectEvent($event)'
        (inputChanged)='onChangeSearch($event)'
        (inputFocused)='onFocused($event)'
        (inputCleared)='onCleared()'
        [initialValue]="_selected"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.text"></a>
    </ng-template>

    <ng-template #notFoundTemplate>
        <div>Nenhuma CID encontrada</div>
    </ng-template>


</div>
