<div class="page">
    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
    <h1 class="page-title secondary"><span>Check list</span> Controle de Acesso</h1>
    <div class="row" style="max-width: 816px; margin: 0 auto;">
        <div class="col-xs-6 col-sm-3" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
            <div class="page-card block">
                <h2 class="block-title">Bloco 1</h2>
                <p>Total 25/25</p>
                <p>25/02/22</p>
            </div>
        </div>
    </div>
</div>
