import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-alarm',
    template: '<router-outlet></router-outlet>',
})
export class AlarmComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
