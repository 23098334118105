<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'gestaoTitle'|translate}} <span> {{'gestaoSubtitle'|translate}}</span></h1>
            <a class="btn btn-gray-fill" (click)="actionExcel()">
                <svg-icon class="right mr-2" src="assets/svg/downloads.svg"></svg-icon>
                {{'exportarexcel'|translate}}
            </a>
            <a class="btn btn-warning" (click)="createUser()">
                <svg-icon class="right mr-2" src="assets/svg/plus.svg"></svg-icon>
                {{'novousario'|translate}}
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" [(ngModel)]="searchUserInput.keyword" class="form-control" type="text"
                       required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>{{'busca'|translate}}</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="unity" [(ngModel)]="searchUserInput.unity" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option *ngFor="let i of unities" [value]="i.id">{{i.name}}</option>
                </select>
                <label for="unity">{{'unidade'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="Tipo" [(ngModel)]="searchUserInput.profile" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option *ngFor="let i of enumProfile">{{i}}</option>
                </select>
                <label for="Tipo">{{'perfil'|translate}}</label>
            </div>
            <div class="input-material select">
                <select id="status" [(ngModel)]="searchUserInput.status" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option [ngValue]="null">{{'todos'|translate}}</option>
                    <option [ngValue]="true">{{'ativo'|translate}}</option>
                    <option [ngValue]="false">{{'inativo'|translate}}</option>
                </select>
                <label for="status">{{'status'|translate}}</label>
            </div>
            <a (click)="onSearch()" class="btn btn-primary btn-lg text-white">{{'buscar'|translate}}</a>
        </div>

        <div class="table-responsive">
            <table class="table table_regular">
                <thead>
                <tr>
                    <th>País</th>
                    <th>{{'unidade'|translate}}</th>
                    <th>RE</th>
                    <th>{{'usuario'|translate}}</th>
                    <th class="text-center">CPF</th>
                    <th class="text-center">{{'statusCadastro'|translate}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(users.results)">
                    <td colspan="8" class="text-center py-4">{{'nenhumainfo'|translate}}</td>
                </tr>
                <tr *ngFor="let i of users.results  |
                paginate: {itemsPerPage: users.pageSize,
                currentPage: users.currentPage,
                totalItems: users.totalCount }" class="is-cursor">
                    <td>
                        <img style="width: 31px;height: 31px;object-fit: cover;border-radius: 100%"
                             [src]="i.unity.country.flag" alt="flag">
                    </td>
                    <td>{{i.unity.name}}</td>
                    <td>{{i.re}}</td>
                    <td>{{i.name}}</td>
                    <td class="text-center">{{i.cpf}}</td>
                    <td class="text-center">{{i.isValidated ? 'ativo' : 'inativo'}}</td>
                    <td>
                        <svg-icon (click)="updateUser(i)" class="is-cursor mr-2" src="assets/svg/edit.svg"></svg-icon>
                        <svg-icon (click)="onDelete(i)" class="is-cursor" src="assets/svg/close-circle.svg"></svg-icon>
                    </td>


                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="modalLogin" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    <b>
                        {{isNullOrUndefined(this.modelUser.id) ? 'Novo Usuário' : 'Editar Usuário'}}
                    </b>
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <div class="col-12 d-flex justify-content-center">
                            <div style="position: relative"
                                 *ngIf="!isNullOrUndefined(croppedImage)||!isNullOrUndefined(modelUser.photo)">
                                <img class="mb-3"
                                     style="width: 108px;height: 108px;border-radius: 100%;border: 3px solid #fff;object-fit: cover"
                                     [src]="croppedImage?croppedImage:modelUser.photo"
                                     (click)="showModal('modalCropLogin')" alt="img"/>
                                <svg-icon src="assets/svg/edit-user.svg"
                                          style="position: absolute;bottom: 19px;right: 8px;"></svg-icon>
                            </div>

                            <div style="position: relative"
                                 *ngIf="isNullOrUndefined(croppedImage)&& isNullOrUndefined(modelUser.photo)"
                                 (click)="showModal('modalCropLogin')">
                                <ng-lottie class="mb-3"
                                           width="120px" [options]="usersAnimation"></ng-lottie>
                                <svg-icon src="assets/svg/new-user.svg"
                                          style="position: absolute;bottom: 28px;right: 16px;"></svg-icon>

                            </div>
                        </div>
                        <!--NAME-->
                        <div class="col-12 col-sm-12">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.name" id="nameUser" class="form-control" type="text"
                                       autocomplete="off"
                                       required maxlength="150"/>
                                <label for="nameUser">{{'nomecompleto'|translate}}</label>
                            </div>
                        </div>
                        <!--RE-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.re" id="reUser" class="form-control" type="text" required
                                       maxlength="15"/>
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.cpf" id="userCPF" class="form-control" type="text"
                                       mask="000.000.000-00"
                                       required maxlength="150"/>
                                <label for="userCPF">CPF</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="countryUser" [(ngModel)]="countryId" [disabled]="!isNullOrUndefined(this.modelUser.id)"
                                        (change)="changeUnties(countryId)" class="form-control" name="type"
                                        required>
                                    <option [ngValue]="null">{{'select-default'|translate}}</option>
                                    <option selected *ngFor="let i of countries"
                                            [ngValue]="i.id">{{i.name}}</option>
                                </select>
                                <label for="countryUser" class="mb-0 mr-3" [ngClass]="{'disabled' : !isNullOrUndefined(this.modelUser.id)}">País</label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select [(ngModel)]="unityId" id="unityUser" class="form-control" name="type" [disabled]="!isNullOrUndefined(this.modelUser.id)"
                                        required>
                                    <option [ngValue]="null">{{'select-default'|translate}}</option>
                                    <option selected *ngFor="let i of unities"
                                            [ngValue]="i.id">{{i.name}}</option>
                                </select>
                                <label for="unityUser" class="mb-0 mr-3" [ngClass]="{'disabled' : !isNullOrUndefined(this.modelUser.id)}">{{'unidade'|translate}}</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.cellphone" id="userPhone" class="form-control" type="text"
                                       mask="(00) 0000-0000||(00) 0 0000-0000"
                                       required maxlength="150"/>
                                <label for="userPhone">{{'telefonerecado'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.phone" id="userPhoneA" class="form-control" type="text"
                                       mask="(00) 0000-0000||(00) 0 0000-0000"
                                       required maxlength="150"/>
                                <label for="userPhoneA">{{'celularpessoal'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input [(ngModel)]="modelUser.email" id="userEmail" class="form-control" type="text"
                                       required maxlength="150"/>
                                <label for="userEmail">{{'emailpessoal'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="input-material disabled">
                                <input [(ngModel)]="modelUser.officeEmail" id="userEmailConfirm" class="form-control"
                                       type="text"
                                       required maxlength="250"/>
                                <label for="userEmailConfirm" class="disabled">{{'emaileurofarma'|translate}}</label>
                            </div>
                        </div>
                        <!--<div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userEmailConfirm" class="form-control" type="text" [(ngModel)]="modelConfirmEmail"
                                       required  maxlength="150"/>
                                <label for="userEmailConfirm">Confirmação E-mail</label>
                            </div>
                        </div>-->
                        <div class="col-12 col-sm-6" *ngIf="isNullOrUndefined(this.modelUser.id)">
                            <div class="input-material">
                                <input [(ngModel)]="password" id="userPass" class="form-control" type="password"
                                       required maxlength="150"/>
                                <label for="userPass">{{'senha'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="isNullOrUndefined(this.modelUser.id)">
                            <div class="input-material">
                                <input [(ngModel)]="passwordConfirm" id="userPassConfirm" class="form-control"
                                       type="password"
                                       required maxlength="150"/>
                                <label for="userPassConfirm">{{'confirmarsenha'|translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onSave()" class="btn btn-primary btn-lg text-white w-100">
                                <span class="text-white m-0">{{'confirmar'|translate}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="4/4"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>
