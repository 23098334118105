import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {DashboardService} from "../../service/dashboard.service";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {
    AuthInput,
    AuthType, CheckReGQL,
    Country, CreateUserGQL,
    CreateUserInput, ForgotPasswordGQL, GetCountriesGQL,
    GetCountriesQuery, GetUnitiesByCountryGQL, GetUnitiesByCountryQuery,
    GetUnitiesGQL,
    GetUnitiesQuery, LoginGQL, ResendUserCodeGQL,
    Unity, User, ValidateUserGQL
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {map} from "rxjs/operators";
import {AnimationOptions} from "ngx-lottie";

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

    paramId: string = null;
    
    file: File = null;
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    model: AuthInput = new AuthInput();
    modelUserPre = new User();
    modelUser: CreateUserInput = new CreateUserInput();
    modelUserPreUnity = undefined;
    modelUserPreCountry = undefined;
    modelConfirmPassword = '';
    modelForgotCPF = '';

    flagFirstAccessUser = true;
    lockUnity = false;

    accessAnimation: AnimationOptions = {
        path: 'assets/lottie/example.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };

    modelCodeValidate: string;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                private getCountriesGQL: GetCountriesGQL,
                private checkReGQL: CheckReGQL,
                private createUserGQL: CreateUserGQL,
                private validateUserGQL: ValidateUserGQL,
                private resendUserCodeGQL: ResendUserCodeGQL,
                private forgotPasswordGQL: ForgotPasswordGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public login: LoginGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);

    }

    ngOnInit(): void {
        this.model.redirectUri = window.location.protocol + '//' + window.location.host + window.location.pathname;
        this.userService.userPre.subscribe({
            next: data => {
                this.modelUserPre = data as User;
                this.modelUser.id = this.modelUserPre.id;
                this.modelUser.name = this.modelUserPre.name;
                this.modelUser.re = this.modelUserPre.re;
                this.modelUser.cpf = this.modelUserPre.cpf;
                this.modelUser.phone = this.modelUserPre.phone;
                this.modelUser.cellphone = this.modelUserPre.cellphone;
                this.modelUser.officeEmail = this.modelUserPre.officeEmail;
                if (!this.isNullOrUndefined(this.modelUser.officeEmail)) {
                    this.modelUser.email = this.modelUserPre.officeEmail;
                }
                this.modelUser.profile = this.modelUserPre.profile;
            }, error: err => super.onError(err)
        });
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                if (this.paramId === 'acesso') {
                    this.showModal('modalLogin');
                    this.flagFirstAccessUser = false;
                    this.modelUser.isOffice = true;
                }
                if (this.paramId === 'validar') {
                    this.showModal('modalLogin2');
                }
            }
        });
        this.getCountries();
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        // this.countries = this.countryQuery.valueChanges.pipe(map(result => result.data.getCountries as Array<Country>));
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.getCountries as Array<Country>;
        });
    }

    getUnitiesByCountry($event: any) {
        this.unityQuery = this.getUnitiesByCountryGQL.watch({input: $event});
        // this.unities = this.unityQuery.valueChanges.pipe(map(result => result.data.getUnities as Array<Unity>));
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
        $('#svg_login_profile').addClass('d-none');
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('profile.edit.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    actionValidateUser() {
        const arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome <br />'},
            {value: this.modelUser.re, text: 'RE <br />'},
            {value: this.modelUser.cpf, text: 'CPF <br />'},
            {value: this.modelUserPreCountry, text: 'País <br />'},
            {value: this.modelUserPreUnity, text: 'Unidade <br />'},
            {value: this.modelUser.phone, text: 'Telefone Recado <br />'},
            {value: this.modelUser.cellphone, text: 'Celular Pessoal <br />'},
            {value: this.modelUser.email, text: 'E-mail Pessoal <br />'}
        ];
        if (this.isNullOrUndefined(this.modelUser.officeEmail)) {
            arrValidateFields.push({value: this.modelUser.password, text: 'Senha <br />'});
            arrValidateFields.push({value: this.modelConfirmPassword, text: 'Confirmar Senha <br />'});
        }
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningFillCorrect')} <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isCPFValid(this.modelUser.cpf)) {
            return;
        }
        if (!this.isEMailValid(this.modelUser.email)) {
            return;
        }
        if (this.isNullOrUndefined(this.modelUser.officeEmail) && (this.modelUser.password !== this.modelConfirmPassword)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                this.translate.instant('baseComponent.warningDiferentFild'), 'warning');
            return;
        }
        // this.nextModal();
        this.actionSave();
    }

    actionSave() {
        this.modelUser.profile = 'user';
        this.modelUser.unity = {id: this.modelUserPreUnity};
        this.createUserGQL.mutate({input: this.modelUser}).subscribe((result) => {
            const a: any = result.data.createUser as User;
            this.modelUserPre = a;
            if (!this.isNullOrUndefined(this.croppedImage)) {
                this.uploadImage();
            } else {
                this.actionCreateUser();
            }
        });
    }

    actionCreateUser() {
        this.closeModal('modalLogin');
        this.showModal('modalLogin2');
    }

    onClearModelUser(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelUser[str] = '';
            }
        });
    }

    actionLogin(): void {
        const arrValidateFields = [
            {value: this.model.cpf, text: 'CPF <br />'},
            {value: this.model.password, text: 'Senha <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningFillCorrect')} <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isCPFValid(this.model.cpf)) {
            return;
        }

        this.login.mutate({input: this.model}).subscribe(
            (data) => {
                const a: any = data;
                if (a.data.login.user.firstLogin) {
                    this.userService.updateUserPre(a.data.login.user);
                    this.showModal('modalLogin');
                    // this.router.navigate(['/login/acesso']);
                    return;
                }
                if (!a.data.login.user.isValidated) {
                    this.showModal('modalLogin2');
                    this.userService.updateUserPre(a.data.login.user);
                    // this.router.navigate(['/login/acesso']);
                    return;
                }
                this.saveLocalUser(a.data.login);
            }, (err) => {
            }
        );

    }

    saveLocalUser(data) {
        const token = data as AuthType;
        this.userService.updateUser(token.user, true, token.token);
        this.router.navigate(['/']);
    }

    // CROP
    uploadImage(): void {
        this.userService.upload(this.file, this.modelUserPre.id, 'user-photo').subscribe(
            data => {
                setTimeout(() => {
                    this.actionCreateUser();
                }, 300);
                // this.loading.hide();
            }, error => super.onError(error));
    }

    actionSendCode() {
        if (this.isNullOrUndefined(this.modelCodeValidate)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                this.translate.instant('baseComponent.warningCorret'), 'warning');
            return;
        }
        this.validateUserGQL.mutate({
            cpf: this.modelUserPre.cpf, // cpf: this.isNullOrUndefined(this.modelUserPre.officeEmail) ? this.modelUserPre.email : this.modelUserPre.officeEmail,
            code: this.modelCodeValidate
        }).subscribe((result) => {
            this.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningCodeLogin'), 'success');
            this.closeModal('modalLogin2');
        });
    }

    actionReSendCode() {
        this.resendUserCodeGQL.mutate({data: this.modelUser.cpf}).subscribe((result) => {
            this.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningCodeSuccess'), 'success');
        });
    }

    actionForgotPassword() {
        const arrValidateFields = [
            {value: this.modelForgotCPF, text: 'CPF <br />'}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningFillCorrect')} <br><br> ${stringError}`, 'warning');
            return;
        }
        if (!this.isCPFValid(this.modelForgotCPF)) {
            return;
        }
        this.forgotPasswordGQL.mutate({input: this.modelForgotCPF}).subscribe((result) => {
            this.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningEmailSend'), 'success');
            this.closeModal('modalForgot');
        });
    }

    onCodeCompleted(code: string) {
        this.modelCodeValidate = code;
    }

    firstAccessClick() {
        this.model = new AuthInput();
        this.modelUserPre = new User();
        this.modelUser = new CreateUserInput();
        this.modelUserPreUnity = undefined;
        this.modelUserPreCountry = undefined;
        this.modelConfirmPassword = '';
        this.modelForgotCPF = '';
        this.userService.updateUserPre(new User());
        super.showModal('modalLogin');
    }

    actionVerifyUserFirstAccess($event: any) {
        setTimeout(()=> {
            if (!this.isNullOrUndefined(this.modelUser.cpf) && !this.isNullOrUndefined(this.modelUser.re) && this.modelUser.cpf.length > 13) {
                if (!this.isCPFValid(this.modelUser.cpf)) {
                    console.log($event);
                    this.flagFirstAccessUser = true;
                    return;
                }
                this.getUserFirstAccess();
            }
        });
    }

    getUserFirstAccess() {
        const o: any = {inputCPF: this.modelUser.cpf, inputRE: this.modelUser.re}
        this.checkReGQL.watch(o).valueChanges.subscribe(({data}) => {
            console.log('data.checkRe');
            console.log(this.isNullOrUndefined(data));
            if (!this.isNullOrUndefined(data)) {
                const el = data.checkRe as User;
                // this.modelUser = new CreateUserInput();
                this.modelUser.id = el.id;
                this.modelUser.re = el.re;
                this.modelUser.cpf = el.cpf;
                this.modelUser.email = el.email;
                this.modelUser.name = el.name;
                this.modelUser.unity = el.unity;
                // this.modelUser.officeEmail = el.officeEmail;
                this.modelUser.phone = el.phone;
                this.modelUser.cellphone = el.cellphone;
                this.modelUser.phone = el.phone;
                this.flagFirstAccessUser = false;
                this.modelUserPreCountry = el.unity.country.id;
                this.modelUserPreUnity = el.unity.id;
                this.getUnitiesByCountry(el.unity.country.id);
                if (!this.isNullOrUndefined(el.unity)) {
                    this.lockUnity = true;
                    console.log('lockado: ', this.lockUnity);
                }
            }
        });
    }

    onShowForgot(){
        this.modelForgotCPF = '';
        this.showModal('modalForgot')
    }
}
