import {Component, OnDestroy, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {
    Country,
    CreateUserGQL, CreateUserInput, CreateUserUnityInput, DeleteUserGQL, GetCountriesGQL, GetUnitiesByCountryGQL,
    GetUnitiesGQL,
    SearchUserGQL,
    SearchUserInput,
    SearchUserQuery,
    Unity, UpdateUserGQL, UpdateUserInput,
    User, UserPaginator
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {AnimationOptions} from 'ngx-lottie';

declare var $: any;

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends BaseComponent implements OnDestroy, AfterContentInit {

    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    file: File = null;

    searchUserInput: SearchUserInput = new SearchUserInput();
    users: UserPaginator = new UserPaginator();
    usersQuery: QueryRef<SearchUserQuery>;
    unities: Unity[] = [];
    modelUser: User = new User();
    password: string | null;
    passwordConfirm: string;
    countries: Country[] = [];
    countryId: string;
    unityId: string;
    usersAnimation: AnimationOptions = {
        path: 'assets/lottie/users.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };


    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private usersGQL: SearchUserGQL,
                public getUnitiesGQL: GetUnitiesGQL,
                public createUserGQL: CreateUserGQL,
                public userService: UserService,
                public updateUserGQL: UpdateUserGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public deleteUserGQL: DeleteUserGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngAfterContentInit(): void {
        this.getUsers();
        this.getUnitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.unities = data.getUnities as Unity[];
        });
        this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.countries = data.getCountries as Country[];
        });
    }

    getUsers(): void {
        this.searchUserInput.profile = 'admin';
        this.usersQuery = this.usersGQL.watch({input: this.searchUserInput});
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.users = data.searchUser as UserPaginator;
        });
    }

    onSearch() {
        this.loading.show();
        this.usersQuery.refetch({input: this.searchUserInput}).finally(() => this.loading.hide());
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('baseComponent.warningUploadExtensions'), this.translate.instant('baseComponent.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    createUser() {
        this.file = null;
        this.modelUser = new User();
        this.countryId = undefined;
        this.unityId = undefined;
        this.password = undefined;
        this.passwordConfirm = undefined;
        super.showModal('modalLogin');
    }

    updateUser(u: User) {
        this.file = null;
        this.modelUser = Object.assign({}, u);
        this.changeUnties(u.unity.country.id);
        this.countryId = u.unity.country.id;
        this.unityId = u.unity.id;
        super.showModal('modalLogin');
    }

    changeUnties(id: string) {
        this.unityId = null;
        this.getUnitiesByCountryGQL.watch({input: id}).valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Unity[];
        });
    }

    onSave() {
        let arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome Completo<br>'},
            {value: this.modelUser.re, text: 'RE<br>'},
            {value: this.modelUser.cpf, text: 'CPF<br>'},
            {value: this.unityId, text: 'Unidade<br>'},
            {value: this.modelUser.cellphone, text: 'Telefone de Recado<br>'},
            {value: this.modelUser.phone, text: 'Celular Pessoal<br>'},
            {value: this.modelUser.email, text: 'E-mail pessoal<br>'},
            {value: this.modelUser.officeEmail, text: 'E-mail EuroFarma<br>'},
            // {value: this.password, text: 'Senha<br>'},
            // {value: this.modelUser.profile, text: 'Perfil<br>'},
        ];

        if (super.isNullOrUndefined(this.modelUser.id)) {
            arrValidateFields.push({value: this.password, text: 'Senha<br>'});
        }
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningFill')} ${stringError} corretamente`);
            return;
        }

        if (super.isNullOrUndefined(this.modelUser.id)) {
            if (this.password !== this.passwordConfirm) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('senhasnaoconferem')));
                return;
            }
        }

        if (!this.isEMailValid(this.modelUser.email)) {
            return;
        }
        if (!this.isEMailValid(this.modelUser.officeEmail)) {
            return;
        }

        if (super.isNullOrUndefined(this.modelUser.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }

    }

    actionCreatedUser() {
        const unityInput: CreateUserUnityInput = {
            id: this.unityId,
        };
        const createInput: CreateUserInput = {
            name: this.modelUser.name,
            re: this.modelUser.re,
            cpf: this.modelUser.cpf,
            unity: unityInput,
            cellphone: this.modelUser.cellphone,
            phone: this.modelUser.phone,
            email: this.modelUser.email,
            officeEmail: this.modelUser.officeEmail,
            password: this.password,
            profile: 'admin',
        };
        this.loading.show();
        this.createUserGQL.mutate({input: createInput}).subscribe(({data}) => {
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(data.createUser.id, this.file);
            }
            this.usersQuery.refetch({input: this.searchUserInput});
            super.closeModal('modalLogin');
            this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('usuarioadicionado')), 'success');
            this.loading.hide();
        }, error => super.onError(error));
    }

    actionUpdateUser() {
        const unityInput: CreateUserUnityInput = {
            id: this.unityId,
        };
        const createInput: UpdateUserInput = {
            id: this.modelUser.id,
            name: this.modelUser.name,
            re: this.modelUser.re,
            cpf: this.modelUser.cpf,
            unity: unityInput,
            cellphone: this.modelUser.cellphone,
            phone: this.modelUser.phone,
            email: this.modelUser.email,
            officeEmail: this.modelUser.officeEmail,
            password: this.password,
            profile: 'admin',
        };
        this.loading.show();
        this.updateUserGQL.mutate({input: createInput}).subscribe(({data}) => {
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(data.updateUser.id, this.file);
            }
            this.usersQuery.refetch({input: this.searchUserInput});
            super.closeModal('modalLogin');
            this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('usuarioalterado')), 'success');
            this.loading.hide();
        }, error => super.onError(error));
    }

    onDelete(u: User) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('excluirusuario')), () => {
            this.loading.show();
            this.deleteUserGQL.mutate({id: u.id}).subscribe(
                (data) => {
                    this.usersQuery.refetch({input: this.searchUserInput});
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'),
                        (this.translate.instant('usuarioexcluido')), 'success');
                }, err => super.onError(err));
        });
    }

    uploadImage(id: string, file: File, success = true) {
        this.userService.upload(file, id, 'user-photo').subscribe(
            data => {
            }, error => super.onError(error));
    }

    actionExcel() {
        this.userService.getExcelUsers(this.searchUserInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalLogin');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }
}
