import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    Country,
    CreateUserGQL,
    CreateUserInput,
    CreateUserUnityInput,
    DeleteUserGQL,
    GetCountriesGQL,
    GetUnitiesByCountryGQL,
    GetUnitiesGQL,
    SearchUserGQL,
    SearchUserInput,
    SearchUserQuery, SearchUserQueryVariables,
    Unity,
    UpdateUserGQL,
    UpdateUserInput,
    User,
    UserPaginator,
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';
import {AnimationOptions} from 'ngx-lottie';
import {ExcelService} from '../../service/excel.service';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    user: User;

    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    file: File = null;

    searchInput: SearchUserInput = new SearchUserInput();
    searchUserQuery: QueryRef<SearchUserQuery, SearchUserQueryVariables>;
    userPage: UserPaginator = new UserPaginator();
    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    unities: Unity[] = [];
    modelUser: User = new User();
    password: string | null;
    passwordConfirm: string;
    countryId: string;
    unityId: string;
    countries: Country[] = [];
    usersAnimation: AnimationOptions = {
        path: 'assets/lottie/users.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };
    listFiles = [];

    pathImageObj = {
        primary: {name: '', path: ''},
        secondary: {name: '', path: ''}
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public usersGQL: SearchUserGQL,
                public userService: UserService,
                public getUnitiesGQL: GetUnitiesGQL,
                public getCountriesGQL: GetCountriesGQL,
                public createUserGQL: CreateUserGQL,
                public updateUserGQL: UpdateUserGQL,
                public getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public deleteUserGQL: DeleteUserGQL,
                private excelService: ExcelService
    ) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.getUsers();
        this.getUnitiesGQL.watch().valueChanges.subscribe(({data}) => {
            this.unities = data.getUnities as Unity[];
        });
        this.getCountriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.countries = data.getCountries as Country[];
        });
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    getUsers(): void {
        this.searchInput.profile = 'user';
        this.searchUserQuery = this.usersGQL.watch({input: this.searchInput});
        this.searchUserQuery.valueChanges.subscribe(({data}) => {
            this.userPage = data.searchUser as UserPaginator;
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('baseComponent.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    onSearch() {
        this.loading.show();
        this.searchUserQuery.refetch({input: this.searchInput}).finally(() => this.loading.hide());
    }

    updateUser(u: User) {
        this.file = null;
        this.modelUser = Object.assign({}, u);
        this.changeUnties(u.unity.country.id);
        this.countryId = u.unity.country.id;
        this.unityId = u.unity.id;
        super.showModal('modalUser');
    }

    changeUnties(id: string) {
        this.unityId = null;
        this.getUnitiesByCountryGQL.watch({input: id}).valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Unity[];
        });
    }

    onDelete(u: User) {
        super.confirmMessage((this.translate.instant('baseComponent.warningAttention')), (this.translate.instant('excluirusuario')), () => {
            this.loading.show();
            this.deleteUserGQL.mutate({id: u.id}).subscribe(
                (data) => {
                    this.searchUserQuery.refetch({input: this.searchInput});
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'),
                        (this.translate.instant('usuarioexcluido')), 'success');
                }, err => super.onError(err));
        });
    }

    onSave() {
        let arrValidateFields = [
            {value: this.modelUser.name, text: 'Nome Completo<br>'},
            {value: this.modelUser.re, text: 'RE<br>'},
            {value: this.modelUser.cpf, text: 'CPF<br>'},
            {value: this.unityId, text: 'Unidade<br>'},
            {value: this.modelUser.cellphone, text: 'Telefone de Recado<br>'},
            {value: this.modelUser.phone, text: 'Celular Pessoal<br>'},
            {value: this.modelUser.email, text: 'E-mail pessoal<br>'},
            {value: this.modelUser.officeEmail, text: 'E-mail EuroFarma<br>'},
            // {value: this.modelUser.profile, text: 'Perfil<br>'},
        ];

        if (super.isNullOrUndefined(this.modelUser.id)) {
            arrValidateFields.push({value: this.password, text: 'Senha<br>'},);
        }
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningFill')} ${stringError} corretamente`);
            return;
        }

        if (super.isNullOrUndefined(this.modelUser.id)) {
            if (this.password !== this.passwordConfirm) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('senhasnaoconferem')));
                return;
            }
        }

        if (!this.isEMailValid(this.modelUser.email)) {
            return;
        }
        if (!this.isEMailValid(this.modelUser.officeEmail)) {
            return;
        }

        if (super.isNullOrUndefined(this.modelUser.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }

    }

    actionCreatedUser() {
        const unityInput: CreateUserUnityInput = {
            id: this.unityId,
        };
        const createInput: CreateUserInput = {
            name: this.modelUser.name,
            re: this.modelUser.re,
            cpf: this.modelUser.cpf,
            unity: unityInput,
            cellphone: this.modelUser.cellphone,
            phone: this.modelUser.phone,
            email: this.modelUser.email,
            officeEmail: this.modelUser.officeEmail,
            password: this.password,
            profile: 'user',
        };
        this.loading.show();
        this.createUserGQL.mutate({input: createInput}).subscribe(({data}) => {
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(data.createUser.id, this.file);
            }
            this.searchUserQuery.refetch({input: this.searchInput});
            super.closeModal('modalUser');
            this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('usuarioadicionado')), 'success');
            this.loading.hide();
        }, error => super.onError(error));
    }

    actionUpdateUser() {
        const unityInput: CreateUserUnityInput = {
            id: this.unityId,
        };
        const createInput: UpdateUserInput = {
            id: this.modelUser.id,
            name: this.modelUser.name,
            re: this.modelUser.re,
            cpf: this.modelUser.cpf,
            unity: unityInput,
            cellphone: this.modelUser.cellphone,
            phone: this.modelUser.phone,
            email: this.modelUser.email,
            officeEmail: this.modelUser.officeEmail,
            password: this.password,
            profile: 'user',
        };
        this.loading.show();
        this.updateUserGQL.mutate({input: createInput}).subscribe(({data}) => {
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(data.updateUser.id, this.file);
            }
            this.searchUserQuery.refetch({input: this.searchInput});
            super.closeModal('modalUser');
            this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('usuarioalterado')), 'success');
            this.loading.hide();
        }, error => super.onError(error));
    }

    uploadImage(id: string, file: File, success = true) {
        this.userService.upload(file, id, 'user-photo').subscribe(
            data => {
            }, error => super.onError(error));
    }

    actionExcel() {
        this.userService.getExcelUsers(this.searchInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionImport(event, type, element) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 60000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        this.loading.show();
        const reader = new FileReader();
        reader.onload = (file) => {
            this.pathImageObj[element].path = file.target.result;
            this.pathImageObj[element].name = files[0].name;
            this.listFiles.push({file: files[0], path: file.target.result, type});
        };
        reader.readAsDataURL(event.target.files[0]);
        this.file = files[0];

        this.excelService.upload(this.file, `/users/import-user/excel`).subscribe({
            next: data => {
                this.getUsers();
                this.loading.hide();
            },
            error: err => {
                super.onError(err);
            }
        });
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    pageChanged(event) {
        this.searchInput.page = event;
        this.getUsers();
    }

    actionExcelMedicalCertificate() {
        this.userService.getExcelUsers(this.searchInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionExcelModel() {
        this.downloadHandlerFile('localhost:4200/assets/images/import/Modelo-import-euro-saude.xlsx');
    }
}
