import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    ApproveMedicalCertificateGQL, ChangeMedicalCertificateSapStatusGQL,
    CreateMedicalCertificateCommentGQL,
    CreateMedicalCertificateCommentInput,
    MedicalCertificate,
    MedicalCertificateGQL,
    MedicalCertificateQuery,
    ReproveMedicalCertificateGQL,
    ReproveMedicalCertificateInput,
    ReviewMedicalCertificateGQL
} from '../../../../generated/graphql';
import {UserService} from '../../../service/user.service';
import {QueryRef} from 'apollo-angular';
import {AnimationOptions} from 'ngx-lottie';

declare var $: any;

@Component({
    selector: 'app-certificate-detail',
    templateUrl: './certificate-detail.component.html',
    styleUrls: ['./certificate-detail.component.scss']
})
export class CertificateDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    paramId: string;
    userId: string;
    certificate: MedicalCertificate = new MedicalCertificate();
    createCommentInput: CreateMedicalCertificateCommentInput = new CreateMedicalCertificateCommentInput();
    medicalCertificateQuery: QueryRef<MedicalCertificateQuery>;
    reproveInput: ReproveMedicalCertificateInput = new ReproveMedicalCertificateInput();
    message: AnimationOptions = {
        path: 'assets/lottie/message.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };
    usersAnimation: AnimationOptions = {
        path: 'assets/lottie/users.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public route2: ActivatedRoute,
                public userService: UserService,
                public medicalCertificateGQL: MedicalCertificateGQL,
                public changeMedicalCertificateSapStatusGQL: ChangeMedicalCertificateSapStatusGQL,
                public createMedicalCertificateCommentGQL: CreateMedicalCertificateCommentGQL,
                public reviewMedicalCertificateGQL: ReviewMedicalCertificateGQL,
                public approveMedicalCertificateGQL: ApproveMedicalCertificateGQL,
                public reproveMedicalCertificateGQL: ReproveMedicalCertificateGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.loading.show()
        this.userService.user.subscribe((data) => {
            this.userId = data.id;
            this.route2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url)) {
                    this.paramId = url.id;
                    this.loading.show();
                    this.medicalCertificateQuery = this.medicalCertificateGQL.watch({id: url.id});
                    this.medicalCertificateQuery.valueChanges.subscribe(({data}) => {
                        this.certificate = data.medicalCertificate as MedicalCertificate;
                        this.loading.hide();
                    });
                }
            });
        }, error => super.onError(error));
    }

    ngOnDestroy(): void {
        $('#modalJustification').remove();
    }

    actionComments() {
        if (super.isNullOrUndefined(this.createCommentInput.comment)) {
            return  this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningComent'));
        }
        this.loading.show();
            this.createCommentInput.medicalCertificate = {id: this.paramId};
            this.createMedicalCertificateCommentGQL.mutate({input: this.createCommentInput}).subscribe(({data}) => {
                this.refetchCertificate();
                this.createCommentInput = new CreateMedicalCertificateCommentInput();
                this.loading.hide();
            }, error => super.onError(error));

    }

    refetchCertificate() {
        this.medicalCertificateQuery.refetch({id: this.paramId});
    }

    onRevision() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('solicitacaorevisao')), () => {
            this.loading.show();
            this.reviewMedicalCertificateGQL.mutate({id: this.paramId}).subscribe(
                (data) => {
                    this.refetchCertificate();
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('atestadorevisao')), 'success');
                    this.router.navigate(['/certificate']);
                }, err => super.onError(err));
        });

    }

    onApproved() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), (this.translate.instant('aprovaratestado')), () => {
            this.loading.show();
            this.approveMedicalCertificateGQL.mutate({id: this.paramId}).subscribe(
                (data) => {
                    this.refetchCertificate();
                    this.loading.hide();
                    this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('atestadoaprovado')), 'success');
                    this.router.navigate(['/certificate']);
                }, err => super.onError(err));
        });

    }

    onJustification() {
        this.reproveInput = new ReproveMedicalCertificateInput();
        super.showModal('modalJustification');
    }

    onReprove() {
        const arrValidateFields = [
            {value: this.reproveInput.reproveJustification, text: 'Justificativa<br>'},
        ];
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningFill')} ${stringError} corretamente`);
            return;
        }
        this.loading.show();
        this.reproveInput.id = this.paramId;
        this.reproveMedicalCertificateGQL.mutate({input: this.reproveInput}).subscribe(
            (data) => {
                super.closeModal('modalJustification');
                this.refetchCertificate();
                this.loading.hide();
                this.showMessage(this.translate.instant('baseComponent.success'), (this.translate.instant('atestadoreprovado')), 'success',() => {
                    this.router.navigate(['/certificate']);
                });
            }, err => super.onError(err));
    }

    changeExport(c: MedicalCertificate) {
        if (c.exportSap) {
            return;
        }
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningLeaveSap'), () => {
            this.changeMedicalCertificateSapStatusGQL.mutate({id: c.id}).subscribe(dt => {
                this.refetchCertificate();
            }, err => super.onError(err));
        }, () => {
            c.exportSap = false;
        });
    }


}
