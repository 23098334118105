import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AuthInput {
  code?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  redirectUri?: InputMaybe<Scalars['String']>;
};

export class AuthType {
  isFirstLogin: Scalars['Boolean'];
  microsoftToken: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export class Cid {
  cid: Scalars['String'];
  id: Scalars['String'];
};

export class Country {
  flag: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unity?: Maybe<Array<Unity>>;
};

export class CreateCountryInput {
  flag: Scalars['String'];
  name: Scalars['String'];
};

export class CreateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export class CreateJobInput {
  payload: Scalars['String'];
  userId: Scalars['String'];
};

export class CreateLimitQtdTypeInput {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export class CreateMedicalCertificateCommentInput {
  comment: Scalars['String'];
  medicalCertificate: CreateMedicalCertificateCommentMedicalCertificateInput;
};

export class CreateMedicalCertificateCommentMedicalCertificateInput {
  id: Scalars['String'];
};

export class CreateMedicalCertificateInput {
  appointmentDate?: InputMaybe<Scalars['DateTime']>;
  appointmentEnd?: InputMaybe<Scalars['DateTime']>;
  appointmentStart?: InputMaybe<Scalars['DateTime']>;
  appointmentType?: InputMaybe<Scalars['String']>;
  /** Enum spouse|son>16|son>18|exceptional|son */
  certificateOwner?: InputMaybe<Scalars['String']>;
  cid?: InputMaybe<Scalars['String']>;
  crm?: InputMaybe<Scalars['String']>;
  emissionDate?: InputMaybe<Scalars['DateTime']>;
  observation?: InputMaybe<Scalars['String']>;
  re: Scalars['String'];
  timeOff?: InputMaybe<Scalars['String']>;
  timeOffStartDate?: InputMaybe<Scalars['DateTime']>;
  /** enum certificate|consult|escort|maternity|donation */
  type: Scalars['String'];
  uf?: InputMaybe<Scalars['String']>;
};

export class CreateNotificationInput {
  date: Scalars['DateTime'];
  message: Scalars['String'];
  relationId: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  user: NotificationUserInput;
};

export class CreateUnityCountryInput {
  id: Scalars['String'];
};

export class CreateUnityInput {
  country: CreateUnityCountryInput;
  name: Scalars['String'];
};

export class CreateUserInput {
  cellphone: Scalars['String'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isOffice?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  officeEmail?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  /** enum user|admin */
  profile: Scalars['String'];
  re: Scalars['String'];
  unity: CreateUserUnityInput;
};

export class CreateUserUnityInput {
  id: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class EnumType {
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export class Job {
  id: Scalars['String'];
  payload: Scalars['String'];
  progress: Scalars['Float'];
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export class LimitQtdType {
  amountPerYear: Scalars['Float'];
  appointmentType: Scalars['String'];
  certificateOwner: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export class MedicalCertificate {
  appointmentDate?: Maybe<Scalars['DateTime']>;
  appointmentEnd?: Maybe<Scalars['DateTime']>;
  appointmentStart?: Maybe<Scalars['DateTime']>;
  appointmentType?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<User>;
  certificateOwner?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  comment?: Maybe<Array<MedicalCertificateComment>>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  crm?: Maybe<Scalars['String']>;
  emissionDate?: Maybe<Scalars['DateTime']>;
  exportSap: Scalars['Boolean'];
  exportSapAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  image?: Maybe<Array<MedicalCertificateImage>>;
  observation?: Maybe<Scalars['String']>;
  ownerBy?: Maybe<User>;
  re: Scalars['String'];
  reproveJustification?: Maybe<Scalars['String']>;
  reprovedAt?: Maybe<Scalars['DateTime']>;
  reprovedBy?: Maybe<User>;
  status: Scalars['String'];
  timeOff?: Maybe<Scalars['String']>;
  timeOffStartDate?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uf?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export class MedicalCertificateComment {
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  id: Scalars['String'];
  medicalCertificate?: Maybe<MedicalCertificate>;
};

export class MedicalCertificateImage {
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  medicalCertificate?: Maybe<MedicalCertificate>;
  url: Scalars['String'];
};

export class MedicalCertificatePaginator {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<MedicalCertificate>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Mutation {
  acceptTerms: User;
  approveMedicalCertificate: MedicalCertificate;
  changeMedicalCertificateSapStatus: MedicalCertificate;
  changePassword: DefaultMessage;
  /** Create a new Country */
  createCountry: Country;
  /** Create new Enum Type */
  createEnumType: EnumType;
  createJob: Job;
  createLimitQtdType: LimitQtdType;
  createMedicalCertificate: MedicalCertificate;
  createMedicalCertificateComment: MedicalCertificateComment;
  createNotification: Notification;
  /** Create a new Unity */
  createUnity: Unity;
  /** Create new User */
  createUser: User;
  /** Remove User By Id */
  deleteUser: DefaultMessage;
  forgotPassword: DefaultMessage;
  login: AuthType;
  readNotification: Notification;
  /** Remove Country By ID */
  removeCountry: DefaultMessage;
  removeEnumType: DefaultMessage;
  removeJob: Job;
  removeLimitQtdType: LimitQtdType;
  removeMedicalCertificateImage: DefaultMessage;
  removeNotification: DefaultMessage;
  /** Remove Unity */
  removeUnity: DefaultMessage;
  reproveMedicalCertificate: MedicalCertificate;
  resendUserCode: DefaultMessage;
  resetPassword: DefaultMessage;
  reviewMedicalCertificate: MedicalCertificate;
  updateEnumType: EnumType;
  updateJob: Job;
  updateLimitQtdType: LimitQtdType;
  updateMedicalCertificate: MedicalCertificate;
  updateNotification: Notification;
  /** Update Unity */
  updateUnity: Unity;
  /** Update User */
  updateUser: User;
  /** Validate user code */
  validateUser: DefaultMessage;
};


export class MutationApproveMedicalCertificateArgs {
  id: Scalars['String'];
};


export class MutationChangeMedicalCertificateSapStatusArgs {
  id: Scalars['String'];
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateCountryArgs {
  data: CreateCountryInput;
};


export class MutationCreateEnumTypeArgs {
  data: CreateEnumTypeInput;
};


export class MutationCreateJobArgs {
  data: CreateJobInput;
};


export class MutationCreateLimitQtdTypeArgs {
  createLimitQtdTypeInput: CreateLimitQtdTypeInput;
};


export class MutationCreateMedicalCertificateArgs {
  data: CreateMedicalCertificateInput;
};


export class MutationCreateMedicalCertificateCommentArgs {
  data: CreateMedicalCertificateCommentInput;
};


export class MutationCreateNotificationArgs {
  data: CreateNotificationInput;
};


export class MutationCreateUnityArgs {
  data: CreateUnityInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteUserArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  cpf: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationReadNotificationArgs {
  id: Scalars['String'];
};


export class MutationRemoveCountryArgs {
  id: Scalars['String'];
};


export class MutationRemoveEnumTypeArgs {
  id: Scalars['String'];
};


export class MutationRemoveJobArgs {
  id: Scalars['String'];
};


export class MutationRemoveLimitQtdTypeArgs {
  id: Scalars['Int'];
};


export class MutationRemoveMedicalCertificateImageArgs {
  id: Scalars['String'];
};


export class MutationRemoveNotificationArgs {
  id: Scalars['String'];
};


export class MutationRemoveUnityArgs {
  id: Scalars['String'];
};


export class MutationReproveMedicalCertificateArgs {
  data: ReproveMedicalCertificateInput;
};


export class MutationResendUserCodeArgs {
  cpf: Scalars['String'];
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationReviewMedicalCertificateArgs {
  id: Scalars['String'];
};


export class MutationUpdateEnumTypeArgs {
  data: UpdateEnumTypeInput;
};


export class MutationUpdateJobArgs {
  data: UpdateJobInput;
};


export class MutationUpdateLimitQtdTypeArgs {
  updateLimitQtdTypeInput: UpdateLimitQtdTypeInput;
};


export class MutationUpdateMedicalCertificateArgs {
  data: UpdateMedicalCertificateInput;
};


export class MutationUpdateNotificationArgs {
  data: UpdateNotificationInput;
};


export class MutationUpdateUnityArgs {
  data: UpdateUnityInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};


export class MutationValidateUserArgs {
  code: Scalars['String'];
  cpf: Scalars['String'];
};

export class Notification {
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  relationId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<User>;
};

export class NotificationUserInput {
  id: Scalars['String'];
};

export class Query {
  checkRe: User;
  cid: Array<Cid>;
  /** Return User Logged */
  currentUser: User;
  enumByType: Array<EnumType>;
  enumType: EnumType;
  enumTypes: Array<EnumType>;
  /** Return All Countries */
  getCountries: Array<Country>;
  /** Get Country By ID */
  getCountry: Country;
  /** Return All Unities */
  getUnities: Array<Unity>;
  /** Return All Unities By CountryId */
  getUnitiesByCountry: Array<Unity>;
  /** Get Unity By ID */
  getUnity: Unity;
  job: Job;
  jobs: Array<Job>;
  limitQtdType: LimitQtdType;
  medicalCertificate: MedicalCertificate;
  medicalCertificates: Array<MedicalCertificate>;
  notification: Notification;
  notifications: Array<Notification>;
  notificationsByUser: UserNotificationDto;
  searchMedicalCertificate: MedicalCertificatePaginator;
  searchUnity: Array<Unity>;
  searchUser: UserPaginator;
  /** Return User By Id */
  user: User;
  /** Return User By Email */
  userByEmail: User;
  /** Return All Users */
  users: UserPaginator;
};


export class QueryCheckReArgs {
  cpf: Scalars['String'];
  re: Scalars['String'];
};


export class QueryCidArgs {
  keyword: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeArgs {
  id: Scalars['String'];
};


export class QueryGetCountryArgs {
  id: Scalars['String'];
};


export class QueryGetUnitiesByCountryArgs {
  id: Scalars['String'];
};


export class QueryGetUnityArgs {
  id: Scalars['String'];
};


export class QueryJobArgs {
  id: Scalars['String'];
};


export class QueryLimitQtdTypeArgs {
  id: Scalars['Int'];
};


export class QueryMedicalCertificateArgs {
  id: Scalars['String'];
};


export class QueryNotificationArgs {
  id: Scalars['String'];
};


export class QuerySearchMedicalCertificateArgs {
  data: SearchMedicalCertificateInput;
};


export class QuerySearchUnityArgs {
  keyword?: InputMaybe<Scalars['String']>;
};


export class QuerySearchUserArgs {
  data: SearchUserInput;
};


export class QueryUserArgs {
  id: Scalars['String'];
};


export class QueryUserByEmailArgs {
  email: Scalars['String'];
};


export class QueryUsersArgs {
  page?: InputMaybe<Scalars['Int']>;
};

export class ReproveMedicalCertificateInput {
  id: Scalars['String'];
  reproveJustification: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export class SearchMedicalCertificateInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  sapStatus?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  /** enum received|review|reproved|approved */
  status?: InputMaybe<Scalars['String']>;
  /** enum certificate|consult|escort|maternity|donation */
  type?: InputMaybe<Scalars['String']>;
  unity?: InputMaybe<Scalars['String']>;
};

export class SearchUserInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  unity?: InputMaybe<Scalars['String']>;
};

export class Unity {
  codes?: Maybe<Array<UnityCode>>;
  country?: Maybe<Country>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  users?: Maybe<Array<User>>;
};

export class UnityCode {
  code: Scalars['String'];
  id: Scalars['String'];
  unity?: Maybe<Unity>;
};

export class UpdateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export class UpdateJobInput {
  id: Scalars['String'];
  payload?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export class UpdateLimitQtdTypeInput {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export class UpdateMedicalCertificateInput {
  appointmentDate?: InputMaybe<Scalars['DateTime']>;
  appointmentEnd?: InputMaybe<Scalars['DateTime']>;
  appointmentStart?: InputMaybe<Scalars['DateTime']>;
  appointmentType?: InputMaybe<Scalars['String']>;
  /** Enum spouse|son>16|son>18|exceptional|son */
  certificateOwner?: InputMaybe<Scalars['String']>;
  cid?: InputMaybe<Scalars['String']>;
  crm?: InputMaybe<Scalars['String']>;
  emissionDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  re?: InputMaybe<Scalars['String']>;
  timeOff?: InputMaybe<Scalars['String']>;
  timeOffStartDate?: InputMaybe<Scalars['DateTime']>;
  /** enum certificate|consult|escort|maternity|donation */
  type?: InputMaybe<Scalars['String']>;
  uf?: InputMaybe<Scalars['String']>;
};

export class UpdateNotificationInput {
  date?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  message?: InputMaybe<Scalars['String']>;
  relationId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<NotificationUserInput>;
};

export class UpdateUnityInput {
  country?: InputMaybe<CreateUnityCountryInput>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  cellphone?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isOffice?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  officeEmail?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  /** enum user|admin */
  profile?: InputMaybe<Scalars['String']>;
  re?: InputMaybe<Scalars['String']>;
  unity?: InputMaybe<UpdateUserUnityInput>;
};

export class UpdateUserUnityInput {
  id: Scalars['String'];
};

export class User {
  acceptTerms?: Maybe<Scalars['Boolean']>;
  cellphone: Scalars['String'];
  cpf: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstLogin: Scalars['Boolean'];
  id: Scalars['ID'];
  isOffice: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  medicalCertificateApprovedBy?: Maybe<Array<MedicalCertificate>>;
  medicalCertificateCommentCreatedBy?: Maybe<Array<MedicalCertificateComment>>;
  medicalCertificateCreatedBy?: Maybe<Array<MedicalCertificate>>;
  medicalCertificateOwnerBy?: Maybe<Array<MedicalCertificate>>;
  medicalCertificateReprovedBy?: Maybe<Array<MedicalCertificate>>;
  name: Scalars['String'];
  notification?: Maybe<Array<Notification>>;
  officeEmail?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  re: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  unity?: Maybe<Unity>;
  updatedAt: Scalars['DateTime'];
};

export class UserNotificationDto {
  notifications: Array<Notification>;
  total: Scalars['Int'];
  unread: Scalars['Int'];
};

export class UserPaginator {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<User>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ChangePasswordInput {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type DefaultMessageFragment = { message: string, status: number };

export type CountryFragment = { id: string, name: string, flag: string };

export type UnityFragment = { id: string, name: string, country?: { id: string, flag: string, name: string } | null };

export type MedicalCertificateCommentFragment = { id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null };

export type UserPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null }> };

export type MedicalCertificatePaginatorFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, appointmentDate?: any | null, appointmentEnd?: any | null, appointmentStart?: any | null, appointmentType?: string | null, observation?: string | null, certificateOwner?: string | null, cid?: string | null, createdAt: any, crm?: string | null, emissionDate?: any | null, exportSap: boolean, exportSapAt?: any | null, re: string, reproveJustification?: string | null, reprovedAt?: any | null, status: string, timeOff?: string | null, timeOffStartDate?: any | null, type: string, uf?: string | null, updatedAt: any, approvedBy?: { id: string } | null, comment?: Array<{ id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null }> | null, createdBy?: { id: string, name: string, re: string } | null, ownerBy?: { id: string, name: string, re: string } | null, image?: Array<{ id: string, url: string }> | null, reprovedBy?: { id: string } | null }> };

export type UserFragment = { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null };

export type MedicalCertificateFragment = { id: string, appointmentDate?: any | null, appointmentEnd?: any | null, appointmentStart?: any | null, appointmentType?: string | null, observation?: string | null, certificateOwner?: string | null, cid?: string | null, createdAt: any, crm?: string | null, emissionDate?: any | null, exportSap: boolean, exportSapAt?: any | null, re: string, reproveJustification?: string | null, reprovedAt?: any | null, status: string, timeOff?: string | null, timeOffStartDate?: any | null, type: string, uf?: string | null, updatedAt: any, approvedBy?: { id: string } | null, comment?: Array<{ id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null }> | null, createdBy?: { id: string, name: string, re: string } | null, ownerBy?: { id: string, name: string, re: string } | null, image?: Array<{ id: string, url: string }> | null, reprovedBy?: { id: string } | null };

export type SearchMedicalCertificateQueryVariables = Exact<{
  input: SearchMedicalCertificateInput;
}>;


export type SearchMedicalCertificateQuery = { searchMedicalCertificate: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, appointmentDate?: any | null, appointmentEnd?: any | null, appointmentStart?: any | null, appointmentType?: string | null, observation?: string | null, certificateOwner?: string | null, cid?: string | null, createdAt: any, crm?: string | null, emissionDate?: any | null, exportSap: boolean, exportSapAt?: any | null, re: string, reproveJustification?: string | null, reprovedAt?: any | null, status: string, timeOff?: string | null, timeOffStartDate?: any | null, type: string, uf?: string | null, updatedAt: any, approvedBy?: { id: string } | null, comment?: Array<{ id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null }> | null, createdBy?: { id: string, name: string, re: string } | null, ownerBy?: { id: string, name: string, re: string } | null, image?: Array<{ id: string, url: string }> | null, reprovedBy?: { id: string } | null }> } };

export type MedicalCertificateQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MedicalCertificateQuery = { medicalCertificate: { id: string, appointmentDate?: any | null, appointmentEnd?: any | null, appointmentStart?: any | null, appointmentType?: string | null, observation?: string | null, certificateOwner?: string | null, cid?: string | null, createdAt: any, crm?: string | null, emissionDate?: any | null, exportSap: boolean, exportSapAt?: any | null, re: string, reproveJustification?: string | null, reprovedAt?: any | null, status: string, timeOff?: string | null, timeOffStartDate?: any | null, type: string, uf?: string | null, updatedAt: any, approvedBy?: { id: string } | null, comment?: Array<{ id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null }> | null, createdBy?: { id: string, name: string, re: string } | null, ownerBy?: { id: string, name: string, re: string } | null, image?: Array<{ id: string, url: string }> | null, reprovedBy?: { id: string } | null } };

export type CreateMedicalCertificateCommentMutationVariables = Exact<{
  input: CreateMedicalCertificateCommentInput;
}>;


export type CreateMedicalCertificateCommentMutation = { createMedicalCertificateComment: { id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null } };

export type ReviewMedicalCertificateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReviewMedicalCertificateMutation = { reviewMedicalCertificate: { id: string } };

export type ApproveMedicalCertificateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApproveMedicalCertificateMutation = { approveMedicalCertificate: { id: string } };

export type ReproveMedicalCertificateMutationVariables = Exact<{
  input: ReproveMedicalCertificateInput;
}>;


export type ReproveMedicalCertificateMutation = { reproveMedicalCertificate: { id: string } };

export type CreateMedicalCertificateMutationVariables = Exact<{
  input: CreateMedicalCertificateInput;
}>;


export type CreateMedicalCertificateMutation = { createMedicalCertificate: { id: string } };

export type UpdateMedicalCertificateMutationVariables = Exact<{
  input: UpdateMedicalCertificateInput;
}>;


export type UpdateMedicalCertificateMutation = { updateMedicalCertificate: { id: string } };

export type ChangeMedicalCertificateSapStatusMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ChangeMedicalCertificateSapStatusMutation = { changeMedicalCertificateSapStatus: { id: string, appointmentDate?: any | null, appointmentEnd?: any | null, appointmentStart?: any | null, appointmentType?: string | null, observation?: string | null, certificateOwner?: string | null, cid?: string | null, createdAt: any, crm?: string | null, emissionDate?: any | null, exportSap: boolean, exportSapAt?: any | null, re: string, reproveJustification?: string | null, reprovedAt?: any | null, status: string, timeOff?: string | null, timeOffStartDate?: any | null, type: string, uf?: string | null, updatedAt: any, approvedBy?: { id: string } | null, comment?: Array<{ id: string, comment: string, createdAt: any, createdBy?: { id: string, name: string, photo?: string | null } | null }> | null, createdBy?: { id: string, name: string, re: string } | null, ownerBy?: { id: string, name: string, re: string } | null, image?: Array<{ id: string, url: string }> | null, reprovedBy?: { id: string } | null } };

export type RemoveMedicalCertificateImageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveMedicalCertificateImageMutation = { removeMedicalCertificateImage: { message: string, status: number } };

export type SearchCidQueryVariables = Exact<{
  keyword: Scalars['String'];
}>;


export type SearchCidQuery = { cid: Array<{ id: string, cid: string }> };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { getCountries: Array<{ id: string, name: string, flag: string }> };

export type CheckReQueryVariables = Exact<{
  inputCPF: Scalars['String'];
  inputRE: Scalars['String'];
}>;


export type CheckReQuery = { checkRe: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } };

export type LoginMutationVariables = Exact<{
  input: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } } };

export type GetUnitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitiesQuery = { getUnities: Array<{ id: string, name: string }> };

export type GetUnitiesByCountryQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetUnitiesByCountryQuery = { getUnitiesByCountry: Array<{ id: string, name: string, country?: { id: string, flag: string, name: string } | null }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { users: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } };

export type ValidateUserMutationVariables = Exact<{
  cpf: Scalars['String'];
  code: Scalars['String'];
}>;


export type ValidateUserMutation = { validateUser: { message: string, status: number } };

export type ResendUserCodeMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type ResendUserCodeMutation = { resendUserCode: { message: string, status: number } };

export type ForgotPasswordMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type SearchUserQueryVariables = Exact<{
  input: SearchUserInput;
}>;


export type SearchUserQuery = { searchUser: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results: Array<{ id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null }> } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { deleteUser: { message: string, status: number } };

export type GetNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationQuery = { notificationsByUser: { total: number, unread: number, notifications: Array<{ id: string, title: string, message: string, type: string, isRead: boolean, relationId?: string | null }> } };

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = { readNotification: { id: string, title: string, message: string, type: string, isRead: boolean } };

export type AcceptTermsMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptTermsMutation = { acceptTerms: { id: string, name: string, re: string, cpf: string, phone: string, cellphone: string, email?: string | null, officeEmail?: string | null, profile: string, photo?: string | null, firstLogin: boolean, isValidated: boolean, isOffice: boolean, acceptTerms?: boolean | null, unity?: { id: string, name: string, country?: { id: string, flag: string, name: string } | null } | null } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  name
  flag
}
    `;
export const UnityFragmentDoc = gql`
    fragment Unity on Unity {
  id
  name
  country {
    id
    flag
    name
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  re
  cpf
  phone
  cellphone
  email
  officeEmail
  profile
  photo
  firstLogin
  isValidated
  isOffice
  acceptTerms
  unity {
    ...Unity
  }
}
    ${UnityFragmentDoc}`;
export const UserPageFragmentDoc = gql`
    fragment UserPage on UserPaginator {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const MedicalCertificateCommentFragmentDoc = gql`
    fragment MedicalCertificateComment on MedicalCertificateComment {
  id
  comment
  createdAt
  createdBy {
    id
    name
    photo
  }
}
    `;
export const MedicalCertificateFragmentDoc = gql`
    fragment MedicalCertificate on MedicalCertificate {
  id
  appointmentDate
  appointmentEnd
  appointmentStart
  appointmentType
  observation
  approvedBy {
    id
  }
  certificateOwner
  cid
  comment {
    ...MedicalCertificateComment
  }
  createdAt
  createdBy {
    id
    name
    re
  }
  ownerBy {
    id
    name
    re
  }
  crm
  emissionDate
  exportSap
  exportSapAt
  image {
    id
    url
  }
  re
  reproveJustification
  reprovedAt
  reprovedBy {
    id
  }
  status
  timeOff
  timeOffStartDate
  type
  uf
  updatedAt
}
    ${MedicalCertificateCommentFragmentDoc}`;
export const MedicalCertificatePaginatorFragmentDoc = gql`
    fragment MedicalCertificatePaginator on MedicalCertificatePaginator {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...MedicalCertificate
  }
}
    ${MedicalCertificateFragmentDoc}`;
export const SearchMedicalCertificateDocument = gql`
    query SearchMedicalCertificate($input: SearchMedicalCertificateInput!) {
  searchMedicalCertificate(data: $input) {
    ...MedicalCertificatePaginator
  }
}
    ${MedicalCertificatePaginatorFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchMedicalCertificateGQL extends Apollo.Query<SearchMedicalCertificateQuery, SearchMedicalCertificateQueryVariables> {
    document = SearchMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MedicalCertificateDocument = gql`
    query MedicalCertificate($id: String!) {
  medicalCertificate(id: $id) {
    ...MedicalCertificate
  }
}
    ${MedicalCertificateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MedicalCertificateGQL extends Apollo.Query<MedicalCertificateQuery, MedicalCertificateQueryVariables> {
    document = MedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMedicalCertificateCommentDocument = gql`
    mutation CreateMedicalCertificateComment($input: CreateMedicalCertificateCommentInput!) {
  createMedicalCertificateComment(data: $input) {
    ...MedicalCertificateComment
  }
}
    ${MedicalCertificateCommentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMedicalCertificateCommentGQL extends Apollo.Mutation<CreateMedicalCertificateCommentMutation, CreateMedicalCertificateCommentMutationVariables> {
    document = CreateMedicalCertificateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReviewMedicalCertificateDocument = gql`
    mutation ReviewMedicalCertificate($id: String!) {
  reviewMedicalCertificate(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewMedicalCertificateGQL extends Apollo.Mutation<ReviewMedicalCertificateMutation, ReviewMedicalCertificateMutationVariables> {
    document = ReviewMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApproveMedicalCertificateDocument = gql`
    mutation ApproveMedicalCertificate($id: String!) {
  approveMedicalCertificate(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApproveMedicalCertificateGQL extends Apollo.Mutation<ApproveMedicalCertificateMutation, ApproveMedicalCertificateMutationVariables> {
    document = ApproveMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReproveMedicalCertificateDocument = gql`
    mutation ReproveMedicalCertificate($input: ReproveMedicalCertificateInput!) {
  reproveMedicalCertificate(data: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReproveMedicalCertificateGQL extends Apollo.Mutation<ReproveMedicalCertificateMutation, ReproveMedicalCertificateMutationVariables> {
    document = ReproveMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMedicalCertificateDocument = gql`
    mutation CreateMedicalCertificate($input: CreateMedicalCertificateInput!) {
  createMedicalCertificate(data: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMedicalCertificateGQL extends Apollo.Mutation<CreateMedicalCertificateMutation, CreateMedicalCertificateMutationVariables> {
    document = CreateMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMedicalCertificateDocument = gql`
    mutation UpdateMedicalCertificate($input: UpdateMedicalCertificateInput!) {
  updateMedicalCertificate(data: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMedicalCertificateGQL extends Apollo.Mutation<UpdateMedicalCertificateMutation, UpdateMedicalCertificateMutationVariables> {
    document = UpdateMedicalCertificateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeMedicalCertificateSapStatusDocument = gql`
    mutation ChangeMedicalCertificateSapStatus($id: String!) {
  changeMedicalCertificateSapStatus(id: $id) {
    ...MedicalCertificate
  }
}
    ${MedicalCertificateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeMedicalCertificateSapStatusGQL extends Apollo.Mutation<ChangeMedicalCertificateSapStatusMutation, ChangeMedicalCertificateSapStatusMutationVariables> {
    document = ChangeMedicalCertificateSapStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMedicalCertificateImageDocument = gql`
    mutation RemoveMedicalCertificateImage($id: String!) {
  removeMedicalCertificateImage(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMedicalCertificateImageGQL extends Apollo.Mutation<RemoveMedicalCertificateImageMutation, RemoveMedicalCertificateImageMutationVariables> {
    document = RemoveMedicalCertificateImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCidDocument = gql`
    query SearchCid($keyword: String!) {
  cid(keyword: $keyword) {
    id
    cid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCidGQL extends Apollo.Query<SearchCidQuery, SearchCidQueryVariables> {
    document = SearchCidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesDocument = gql`
    query GetCountries {
  getCountries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
    document = GetCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckReDocument = gql`
    query CheckRe($inputCPF: String!, $inputRE: String!) {
  checkRe(cpf: $inputCPF, re: $inputRE) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckReGQL extends Apollo.Query<CheckReQuery, CheckReQueryVariables> {
    document = CheckReDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: AuthInput!) {
  login(data: $input) {
    user {
      ...User
    }
    token
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitiesDocument = gql`
    query GetUnities {
  getUnities {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitiesGQL extends Apollo.Query<GetUnitiesQuery, GetUnitiesQueryVariables> {
    document = GetUnitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitiesByCountryDocument = gql`
    query GetUnitiesByCountry($input: String!) {
  getUnitiesByCountry(id: $input) {
    ...Unity
  }
}
    ${UnityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitiesByCountryGQL extends Apollo.Query<GetUnitiesByCountryQuery, GetUnitiesByCountryQueryVariables> {
    document = GetUnitiesByCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query Users {
  users {
    ...UserPage
  }
}
    ${UserPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateUserDocument = gql`
    mutation ValidateUser($cpf: String!, $code: String!) {
  validateUser(cpf: $cpf, code: $code) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateUserGQL extends Apollo.Mutation<ValidateUserMutation, ValidateUserMutationVariables> {
    document = ValidateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendUserCodeDocument = gql`
    mutation ResendUserCode($data: String!) {
  resendUserCode(cpf: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendUserCodeGQL extends Apollo.Mutation<ResendUserCodeMutation, ResendUserCodeMutationVariables> {
    document = ResendUserCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: String!) {
  forgotPassword(cpf: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUserDocument = gql`
    query SearchUser($input: SearchUserInput!) {
  searchUser(data: $input) {
    ...UserPage
  }
}
    ${UserPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUserGQL extends Apollo.Query<SearchUserQuery, SearchUserQueryVariables> {
    document = SearchUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNotificationDocument = gql`
    query GetNotification {
  notificationsByUser {
    total
    unread
    notifications {
      id
      title
      message
      type
      isRead
      relationId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationGQL extends Apollo.Query<GetNotificationQuery, GetNotificationQueryVariables> {
    document = GetNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: String!) {
  readNotification(id: $id) {
    id
    title
    message
    type
    isRead
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReadNotificationGQL extends Apollo.Mutation<ReadNotificationMutation, ReadNotificationMutationVariables> {
    document = ReadNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTermsDocument = gql`
    mutation AcceptTerms {
  acceptTerms {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTermsGQL extends Apollo.Mutation<AcceptTermsMutation, AcceptTermsMutationVariables> {
    document = AcceptTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }