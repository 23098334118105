import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {CurrentUserGQL, User} from '../../generated/graphql';
import {UserService} from '../service/user.service';
import {switchMap, take} from 'rxjs/operators';
import {of, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserResolver implements Resolve<User> {
    constructor(private service: UserService,
                public router: Router,
                private currentUserGQL: CurrentUserGQL) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<User> | Promise<User> | User {
        if (this.service.getStatusAuthenticated()) {
            return this.currentUserGQL.watch().valueChanges.pipe(take(1), switchMap(({data}) => {
                this.service.updateUser(data.currentUser as User, false);
                return of(data.currentUser as User);
            }));
        } else {
            localStorage.clear();
            this.service.token = null;
            this.router.navigate(['/login']);
            return null;
        }
    }
}
